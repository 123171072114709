import { randomUUID } from "@warrenio/utils/ponyfill/randomUUID";
import { DAYS } from "@warrenio/utils/timeUnits";
import { globalRandom, randomInt } from "../utils/random.ts";

export { randomInt } from "../utils/random.ts";

//#region Base utilities
export function randomArray<T>(length: number, gen: () => T, postMod?: (items: T[]) => T[]) {
    const result = Array.from({ length }, gen);
    return postMod ? postMod(result) : result;
}

/** Returns a random integer between {@link min} (inclusive) and {@link max} (inclusive). */
export function randomIntBetween(min: number, max: number) {
    return min + randomInt(max - min + 1);
}

/** Returns a random number between {@link min} (inclusive) and {@link max} (exclusive). */
export function randomFloatBetween(min: number, max: number) {
    return min + globalRandom() * (max - min);
}

export function randomBoolean() {
    return globalRandom() < 0.5;
}

export function randomElement(arr: string): string;
export function randomElement<T>(arr: T[]): T;
export function randomElement<T>(arr: T[] | string) {
    return arr[randomInt(arr.length)];
}

export function randomFromChars(chars: string, length = 8) {
    return randomArray(length, () => randomElement(chars)).join("");
}
//#endregion

export function randomUuid() {
    return randomUUID();
}

/** Generate a large integer value that will probably not conflict with existing IDs.
 *
 * In request handlers, prefer using `generateId()` instead for primary keys.
 */
export function randomBigInt() {
    return 1000000 + randomInt(1000000000);
}

/** example: "2024-01-01 13:45:56" */
function toTzString(date: Date) {
    return date
        .toISOString()
        .replace("T", " ")
        .replace(/\.\d+Z$/, "");
}

export function currentDateTime() {
    return toTzString(new Date());
}

export function currentDateTimeEpoch(): number {
    return Math.floor(+new Date() / 1000);
}

export function randomPastDateTime() {
    const now = new Date();
    const past = new Date(now.getTime() - randomInt(DAYS * 365));
    return toTzString(past);
}

export function randomLowerAlpha(length = 8) {
    return randomFromChars("abcdefghijklmnopqrstuvwxyz", length);
}

export function randomAlphaNumeric(length = 8) {
    return randomFromChars("abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789", length);
}

export function randomLowerAlphaNumeric(length = 8) {
    return randomFromChars("abcdefghijklmnopqrstuvwxyz0123456789", length);
}

export function randomUpperAlphaNumeric(length = 8) {
    return randomLowerAlphaNumeric(length).toUpperCase();
}

export function randomHex(length: number) {
    return randomFromChars("0123456789abcdef", length);
}

export function randomMac() {
    return randomArray(6, () => randomHex(2)).join(":");
}

export function randomPrivateIpv4() {
    const octets = [10, ...randomArray(3, () => randomInt(256))];
    return octets.join(".");
}

export function randomSubnetIpv4() {
    const cidr = randomIntBetween(1, 128);
    return `${randomPrivateIpv4()}/${cidr}`;
}

export function randomSubnetIpv6() {
    const randomHexGroup = () => randomHex(4);

    const ipv6Address = randomArray(8, randomHexGroup).join(":");
    const cidr = randomIntBetween(1, 128);
    return `${ipv6Address}/${cidr}`;
}

export function randomEmail(): string {
    const username = randomLowerAlphaNumeric(7);
    const randomDomain = randomLowerAlphaNumeric(7);

    const domainExtension = randomElement(["com", "net", "org", "io", "co", "xyz"]);

    return `${username}@${randomDomain}.${domainExtension}`;
}

export function randomTitle() {
    // prettier-ignore
    const adjectives = ["Amazing", "Awesome", "Beautiful", "Breathtaking", "Brilliant", "Charming", "Clever", "Delightful", "Elegant", "Enchanting", "Excellent", "Extraordinary", "Fabulous", "Fantastic", "Gorgeous", "Graceful", "Great", "Impressive", "Incredible", "Lovely", "Magnificent", "Marvelous", "Outstanding", "Perfect", "Remarkable", "Spectacular", "Splendid", "Stunning", "Superb", "Terrific", "Wonderful"];
    // prettier-ignore
    const nouns = ["Adventure", "Art", "Beauty", "Charm", "Creativity", "Elegance", "Excellence", "Fascination", "Glamour", "Grace", "Greatness", "Happiness", "Harmony", "Hope", "Joy", "Love", "Luxury", "Magic", "Passion", "Peace", "Power", "Prestige", "Purity", "Serenity", "Splendor", "Success", "Talent", "Truth", "Victory", "Wealth", "Wisdom"];
    // prettier-ignore
    const what = [ "Universe", "World", "Earth", "Planet", "Galaxy", "Cosmos", "Heavens", "Stars", "Sky", "Ocean", "Sea", "Mountains", "Forest", "Desert", "Jungle", "Tundra", "Prairie", "Savannah", "River", "Lake", "Waterfall", "Volcano", "Island", "Continent", "Country", "City", "Village", "Town"];

    const long = randomBoolean();

    return long
        ? `${randomElement(adjectives)} ${randomElement(nouns)} of the ${randomElement(what)}`
        : `${randomElement(adjectives)} ${randomElement(nouns)}`;
}
