import invariant from "tiny-invariant";

export function startsWithPrefix(prefix: string, path: string) {
    return path.startsWith(prefix) && (path.length === prefix.length || path[prefix.length] === "/");
}

export function removePrefix(prefix: string, path: string) {
    return matchPath(prefix, path) ?? path;
}

/**
 * Checks that {@link path} starts with {@link prefix}.
 * @returns The remaining path after the prefix, or `null` if the prefix does not match.
 * @example
 *   if (matchPath("/foo", location.pathname) != null) { ... }
 */
export function matchPath(prefix: string, path: string) {
    invariant(prefix.startsWith("/"), "Prefix must start with a slash");
    invariant(!prefix.endsWith("/") || prefix === "/", "Prefix must not end with a slash");

    if (!startsWithPrefix(prefix, path)) {
        return null;
    }
    return path.slice(prefix.length);
}

export function isFinalSegment(path: string) {
    return path === "" || path === "/";
}

export function addTrailingSlash(path: string) {
    return path.endsWith("/") ? path : `${path}/`;
}
