import L from "../Login.module.css";

import { useAtomValue, useStore } from "jotai/react";
import { useEffect, useState } from "react";
import { useForm, type SubmitHandler } from "react-hook-form";
import invariant from "tiny-invariant";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { WButton, WSubmitButton } from "../../../components/button/WButton.tsx";
import { WHookForm } from "../../../components/forms/WHookForm.tsx";
import { Loading } from "../../../components/loading/Loading.tsx";
import { isBuiltRelease } from "../../../utils/environment.ts";
import { jsonEncodedBody } from "../../../utils/fetchClient.ts";
import { useSimpleMutation } from "../../../utils/react/useSimpleMutation.tsx";
import { getResponseData } from "../../api/apiClient.ts";
import { apikeyAtom } from "../../api/apikey.store.ts";
import { unauthApiClientAtom } from "../../api/createApiClient.ts";
import { getRelativeUrl } from "../../main/urls.ts";
import { InputRow } from "../InputRow.tsx";
import { LoginBox, LoginLayout } from "../LoginLayout.tsx";
import type { Auth0CallbackResult } from "./auth0Callback.ts";
import { auth0ProviderAtom, exceptionToMessage } from "./auth0Provider.ts";

interface Inputs {
    username: string;
    password: string;
}

export function Auth0LoginForm({
    isSignup,
    onPostSignup,
}: {
    isSignup: boolean;
    onPostSignup: (username: string) => void;
}) {
    const form = useForm<Inputs>({ defaultValues: {} });
    const authProvider = useAtomValue(auth0ProviderAtom);
    const [error, setError] = useState<string | null>(null);

    const onSubmit: SubmitHandler<Inputs> = async ({ username, password }) => {
        // TODO: Redirect path
        const redirectPath = "";
        try {
            if (isSignup) {
                const result = (await authProvider.signup({ username, password, redirectPath })) as {
                    Id?: string;
                    email?: string;
                    emailVerified?: boolean;
                };
                if (result?.Id && result?.email && result?.emailVerified === false) {
                    onPostSignup(result.email);
                }
            } else {
                await authProvider.login({ username, password, redirectPath });
            }
        } catch (error) {
            setError(exceptionToMessage(error));
        }
    };

    return (
        <WHookForm form={form} onSubmit={onSubmit}>
            <InputRow name="username" label="Username" type="username" autoComplete="username" />
            <InputRow name="password" label="Password" type="password" autoComplete="password" />

            <WSubmitButton
                size="lg"
                fontSize="font-size-subtitle"
                className={L.Button}
                action={undefined}
                isLoading={form.formState.isSubmitting}
            >
                {isSignup ? "Sign Up" : "Log In"}
            </WSubmitButton>

            {error && (
                <div className="react-aria-FieldError pt-2">
                    <b>Error:</b> {error}
                </div>
            )}
        </WHookForm>
    );
}

export function Auth0Callback({ params }: { params: Auth0CallbackResult }) {
    //#region Hooks
    const store = useStore();

    const loginMutation = useSimpleMutation(async () => {
        const client = store.get(unauthApiClientAtom);
        invariant(!("error" in params), "Auth0 login error");
        const response = await client.POST("/user-resource/auth0_callback", {
            ...jsonEncodedBody,
            body: params,
        });
        const data = getResponseData(response);
        if (isBuiltRelease) {
            onLogin(data.apikey);
        }
        return data;
    });

    useEffect(() => {
        if (loginMutation.state === "idle") {
            loginMutation.mutate();
        }
    }, [loginMutation]);

    //#endregion

    function onLogin(apikey: string) {
        store.set(apikeyAtom, apikey);
        const targetUrl = getRelativeUrl("/");
        window.history.replaceState({}, "", targetUrl);
    }

    function doEnter() {
        invariant(loginMutation.state === "hasData");
        onLogin(loginMutation.data.apikey);
    }

    const json = JSON.stringify(params, null, 2);
    return (
        <LoginLayout>
            <LoginBox title="Logging in...">
                <div className="children:mb-4">
                    {loginMutation.state === "loading" && <Loading icon="none" />}
                    {loginMutation.state === "hasError" && (
                        <div className="color-text bg-red-1 rounded p-2">{String(loginMutation.error)}</div>
                    )}
                    {loginMutation.state === "hasData" && (
                        <WButton color="primary" label="Enter the zone" action={doEnter} />
                    )}
                    <div className="color-text bg-grey-1 rounded p-2">
                        <pre className="text-nowrap overflow-x-scroll m-0 mb-2 p-1 rounded b b-solid b-gray-3">
                            {json}
                        </pre>
                        <ClipBoardTooltip text={json}>Copy</ClipBoardTooltip>
                    </div>
                </div>
            </LoginBox>
        </LoginLayout>
    );
}
