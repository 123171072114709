import FF from "./FormField.module.css";

import type { TextFieldProps } from "react-aria-components";
import { TextField } from "react-aria-components";
import { cn } from "../../utils/classNames.ts";
import { FormFieldChildren, type FormFieldChildrenProps } from "./FormField.tsx";
import { standardFieldAttributes, type StandardFieldProps } from "./standardFieldAttributes.tsx";

export interface WTextFieldProps
    extends Omit<TextFieldProps, "children" | "className">,
        StandardFieldProps,
        FormFieldChildrenProps {
    className?: string;
    multilineRows?: number;
}

export function WTextField({ children, className, ...props }: WTextFieldProps) {
    return (
        <TextField className={cn(FF.FormField, className)} {...props} {...standardFieldAttributes(props)}>
            <FormFieldChildren {...props}>{children}</FormFieldChildren>
        </TextField>
    );
}
