import type { ComponentProps, ReactNode } from "react";
import {
    FormProvider,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    useFormContext,
    type FieldValues,
    type SubmitErrorHandler,
    type SubmitHandler,
    type UseFormReturn,
} from "react-hook-form";
import type { BaseProps } from "../../utils/baseProps.ts";

/** Common props for a form component that takes default input values and notifies when its inputs are accepted. */
export interface FormWithDefaultsProps<T> {
    /** Initial values for inputs */
    defaultValues?: Partial<T>;
    /** Should be called whenever form values are "accepted" (eg. on blur).
     *
     * `undefined` whenever the form inputs should be reset to their default values. (eg. after a successful submit).
     */
    onSave?: (inputs: T | undefined) => void;
}

interface WHookFormProps<T extends FieldValues> extends Omit<ComponentProps<"form">, "onSubmit" | "onInvalid"> {
    form: UseFormReturn<T>;

    onSubmit: SubmitHandler<T>;
    onInvalid?: SubmitErrorHandler<T>;

    onTrySubmit?: () => void;

    onSave?: FormWithDefaultsProps<T>["onSave"];
    loader?: ReactNode;
}

/**
 * Standard wrapper around react-hook-form that also provides a form context (usable with {@link useFormContext}).
 *
 * Calls `onSave` automatically on form events.
 */
export function WHookForm<T extends FieldValues>({
    form,
    onSubmit,
    onSave,
    onBlur,
    onInvalid,
    onTrySubmit,
    loader,
    ...props
}: WHookFormProps<T>) {
    const { getValues, handleSubmit } = form;

    const finalOnBlur: typeof onBlur = onSave
        ? (e) => {
              onBlur?.(e);
              onSave(getValues());
          }
        : onBlur;

    const finalOnSubmit: SubmitHandler<T> = async (data) => {
        console.debug("WHookForm: onSubmit, inputs: %o", data);
        onTrySubmit?.();
        await onSubmit(data);

        console.debug("WHookForm: Calling reset");
        onSave?.(undefined);
    };

    const finalOnInvalid: SubmitErrorHandler<T> = (errors) => {
        console.debug("WHookForm: onInvalid, errors: %o", errors);
        onTrySubmit?.();

        onInvalid?.(errors);
    };

    return (
        <>
            {loader}

            <FormProvider {...form}>
                <form
                    // TODO: Might not be the best idea - but the form loses data if it is removed from dom
                    className={loader ? "important:hidden" : undefined}
                    // Also call `onInvalid` when native form validation fails (not just react-hook-form validation)
                    onInvalid={(e) => {
                        console.debug("DOM invalid event for input: %o", e.target);
                        finalOnInvalid({});
                    }}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onSubmit={handleSubmit(finalOnSubmit, finalOnInvalid)}
                    onReset={() => {
                        onSave?.(undefined);
                    }}
                    onBlur={finalOnBlur}
                    {...props}
                />
            </FormProvider>
        </>
    );
}

export function WFakeForm<T extends FieldValues>({
    form,
    children,
    ...props
}: { form: UseFormReturn<T>; children: ReactNode } & BaseProps) {
    return (
        <FormProvider {...form}>
            <div data-form {...props}>
                {children}
            </div>
        </FormProvider>
    );
}
