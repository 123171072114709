import { useAtom } from "jotai/react";
import { WButton, type WButtonProps } from "../../../components/button/WButton.tsx";
import { debugBarVisibleAtom } from "./DebugMenu.store.ts";

export function BugButton({ isActive, ...props }: { isActive: boolean } & WButtonProps) {
    return (
        <WButton
            color="primary"
            variant={isActive ? "border" : "ghost"}
            size="bar"
            icon="i-lucide:bug"
            ariaLabel="Debug menu"
            {...props}
        />
    );
}

/** In a separate file to split dependency graph */
export function DebugButton() {
    const [visible, setVisible] = useAtom(debugBarVisibleAtom);
    return <BugButton action={() => setVisible((v) => !v)} isActive={visible} />;
}

export default DebugButton;
