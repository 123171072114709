// NB: we do not import any actual functions here so we can load this module without loading the rest of the SDK
import type auth0 from "auth0-js";

import { discardPromise } from "@warrenio/utils/promise/discardPromise";
import { atom } from "jotai/vanilla";
import { addDevVars } from "../../../dev/initDev.tsx";
import { auth0DomainAtom, auth0OptionsAtom } from "./auth0Config.ts";

let auth0Promise: Promise<typeof import("auth0-js")> | undefined;

function loadAuth0() {
    // Lazy load to decrease bundle size
    return (auth0Promise ??= import("auth0-js"));
}

export function preloadAuth0() {
    discardPromise(loadAuth0());
}

export async function importAuth0() {
    return await loadAuth0();
}

export const webAuthAtom = atom<Promise<auth0.WebAuth>>(async (get) => {
    get(auth0DomainAtom).warnIfInvalid();

    const auth0 = await importAuth0();
    const webAuth = new auth0.WebAuth(get(auth0OptionsAtom));
    addDevVars({ webAuth });
    console.debug("Auth0 client: %o", webAuth);
    return webAuth;
});
