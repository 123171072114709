import { z } from "zod";
import { extendZodWithOpenApi } from "zod-openapi";
import * as spec from "./common.ts";

extendZodWithOpenApi(z);

//#region Common query and path parameters
export const location = z
    .string()
    .describe("Data center code")
    .openapi({ example: "tll", param: { name: "location", in: "path", ref: "location" } });

export const billing_account_id = spec.billing_account_id
    .optional()
    .openapi({ param: { name: "billing_account_id", in: "query", ref: "billing_account_id" } });

export const id = z.string().openapi({ param: { name: "id", in: "path", ref: "id" } });

export const uuid = z.string().openapi({ param: { name: "uuid", in: "path", ref: "uuid" } });
export const target_uuid = z.string().openapi({ param: { name: "target_uuid", in: "path", ref: "target_uuid" } });
export const rule_uuid = z.string().openapi({ param: { name: "rule_uuid", in: "path", ref: "rule_uuid" } });
//#endregion
