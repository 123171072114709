export interface Auth0CallbackSuccess {
    access_token: string;
    id_token: string;
    scope: string;
    expires_in: string;
    token_type: string;

    state: string;

    error: never;
}

export interface Auth0CallbackError {
    error: string;
    error_description: string;

    state: string;
}

export type Auth0CallbackResult = Auth0CallbackSuccess | Auth0CallbackError;

export function getAuth0CallbackParams() {
    const { hash } = document.location;
    const params = new URLSearchParams(hash.slice(1));
    if (params.has("error") || params.has("state")) {
        return Object.fromEntries(params.entries()) as object as Auth0CallbackResult;
    }
    return null;
}
