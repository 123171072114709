import L from "./Login.module.css";

import type { ReactNode } from "react";
import React from "react";
import { useConfig } from "../../config.ts";
import { cn } from "../../utils/classNames.ts";
import { ApiPrefixSelect } from "../main/debug/ApiPrefixSelect.tsx";
import { WChatProvider } from "../support/WChatProvider.tsx";
import { useThemeProps } from "../theme/useTheme.ts";

const hasDebugBar = !import.meta.env.PROD;

const SiteThemeSelect = React.lazy(() => import("../main/debug/SiteThemeSelect.tsx"));

export function LoginLayout({ children }: { children: ReactNode }) {
    const { loginIntroImg, loginIntro } = useThemeProps();
    const { siteName, loginTitle, loginSubtitle } = useConfig();

    return (
        <WChatProvider>
            <div className={cn(L.Login, "size-full")}>
                <div className={L.Intro} {...loginIntro}>
                    {loginIntroImg && <img alt={siteName} {...loginIntroImg} />}
                    {!!loginTitle && <h1 className={L.Title} dangerouslySetInnerHTML={{ __html: loginTitle }} />}
                    {!!loginSubtitle && (
                        <h2 className={L.Subtitle} dangerouslySetInnerHTML={{ __html: loginSubtitle }} />
                    )}
                </div>

                {children}
            </div>
            {hasDebugBar && (
                <div className="absolute HStack gap-2" style={{ top: "10px", left: "10px" }}>
                    <SiteThemeSelect />
                    <ApiPrefixSelect />
                </div>
            )}
        </WChatProvider>
    );
}

export function LoginBox({ title, children }: { title: string; children: ReactNode }) {
    const { loginLogoImg, loginFormBefore, loginForm, loginFormInner } = useThemeProps();
    const { siteName } = useConfig();

    return (
        <div className={L.Form} {...loginForm}>
            <div className={L.Before} {...loginFormBefore} />

            <div className={L.Inner} {...loginFormInner}>
                {loginLogoImg && (
                    <div className={L.Logo}>
                        <img alt={siteName} {...loginLogoImg} />
                    </div>
                )}

                <h2 className={cn(L.Title, "text-center")}>{title}</h2>

                {children}
            </div>
        </div>
    );
}
