import { z } from "zod";
import { extendZodWithOpenApi, type ZodOpenApiPathsObject } from "zod-openapi";
import { errorResponse, formBody, jsonBody, successResponse } from "../util.ts";
import { InvoiceStatusEnum } from "./billingEnums.ts";
import {
    billing_account_id,
    datetime_epoch_s,
    datetime_z,
    int,
    int_or_string,
    json_string,
    not_a_uuid,
    simpleSuccessResponse,
    user_id,
} from "./common.ts";

extendZodWithOpenApi(z);

const LinkMethodId = z
    .string()
    .describe("Payment link method ID")
    .openapi({ ref: "LinkMethodId", example: "duitku::BK" });

const ProcessorMethodId = z
    .string()
    .describe("Payment processor method ID")
    .openapi({ ref: "ProcessorMethodId", example: "omise_creditcard" });

export const BillingAdditionalData = z
    .object({ link_methods: z.array(LinkMethodId) })
    .openapi({ ref: "BillingAdditionalData" });

const ProcessorData = z
    .object({
        id: z.string(),
        object: z.string(),
        allow_redisplay: z.string(),
        billing_details: z
            .object({
                address: z.object({
                    city: z.string().nullable(),
                    country: z.string().nullable(),
                    line1: z.string().nullable(),
                    line2: z.string().nullable(),
                    postal_code: z.string().nullable(),
                    state: z.string().nullable(),
                }),
                email: z.string().nullable(),
                // XXX: Backend should probably not allow this to be nullable (but it can be unless the frontend explicitly sends it)
                name: z.string().nullable(),
                phone: z.string().nullable(),
            })
            // TODO: are all fields here really optional?
            .partial()
            .passthrough(),
        card: z
            .object({
                brand: z.string(),
                checks: z.object({
                    address_line1_check: z.string().nullable(), // TODO might not be string?
                    address_postal_code_check: z.string().nullable(), // TODO might not be string?
                    cvc_check: z.string(),
                }),
                country: z.string(),
                display_brand: z.string(),
                exp_month: int,
                exp_year: int,
                fingerprint: z.string(),
                funding: z.string(),
                generated_from: z.string().nullable(), // TODO might not be string?
                last4: z.string(),
                networks: z.object({
                    available: z.array(z.string()),
                    preferred: z.string().nullable(),
                }),
                three_d_secure_usage: z.object({ supported: z.boolean() }),
                wallet: z.string().nullable(), // TODO might not be string?
            })
            // TODO: are all fields here really optional?
            .partial()
            .passthrough(),

        street1: z.string().nullable(),
        street2: z.string().nullable(),
        city: z.string().nullable(),
        state: z.string().nullable(),
        postal_code: z.string().nullable(),
        country: z.string().nullable(),
        phone_number: z.string().nullable(),
        financing: z.string(),
        bank: z.string(),
        brand: z.string(),
        fingerprint: z.string(),
        first_digits: z.string().nullable(),
        last_digits: z.string().nullable(),
        name: z.string(),
        expiration_month: int,
        expiration_year: int,
        security_code_check: z.boolean(),
        tokenization_method: z.string().nullable(),
        created_at: datetime_z,

        created: int,
        customer: z.string(),
        deleted: z.boolean(),
        livemode: z.boolean(),
        location: z.string(),
        metadata: z.object({}),
        type: z.string(),
    })
    .passthrough()
    // TODO: are all fields here really optional?
    .partial()
    .optional()
    .openapi({ ref: "ProcessorData" });

const BillingAccountCardType = z.enum(["creditcard", "paying_by_invoice"]).openapi({ ref: "BillingAccountCardType" });

export const BillingAccountCardData = z
    .object({
        id: z.string(),
        expire_month: int_or_string.optional(),
        expire_year: int_or_string.optional(),
        last4: z.string().optional(),
        brand: z.string().optional(), // TODO: this field needs an example in mock data
        card_type: z.string().optional(),
        card_holder: z.string().nullable().optional(),
        type: BillingAccountCardType,
        is_verified: z.boolean().optional(),
        // TODO: split these up by processor type?
        processor_data: ProcessorData,
        client: z.object({}).optional(), // for invoice payment
    })
    .openapi({ ref: "BillingAccountCardData" });

/**
 * NB: !!! Do not confuse with `BillingAccountCardData`
 */
export const BillingAccountCard = z
    .object({
        additional_data: json_string.describe("JSON string containing `BillingAccountCardData`"),
        billing_account_id,
        billing_account_processor_identifier_id: int,
        created: datetime_epoch_s,
        id: int,
        identifier: z.string(),
        is_deleted: z.boolean(),
        is_primary: z.boolean(),
        is_verification_pending: z.boolean(),
        is_verified: z.boolean(),
        valid_thru: datetime_epoch_s,
    })
    .openapi({ ref: "BillingAccountCard" });

const RestrictionLevel = z.enum(["CLEAR", "LIMITED", "FROZEN", "TERMINATED"]).openapi({ ref: "RestrictionLevel" });

const PromotionCreditType = z
    .enum(["first_free", "referral_receiver", "signup_campaign"])
    .openapi({ ref: "PromotionCreditType" });

export const BillingAccountEligiblePromotion = z
    .object({
        credit_type: PromotionCreditType,
        amount: z.number(),
        top_up_requirement: z.number().optional(),
        top_up_done: z.number().optional(),
    })
    .openapi({ ref: "BillingAccountEligiblePromotion" });

const BillingAccountBaseFields = z
    .object({
        address_line1: z.string().optional(),
        address_line2: z.string().optional(),
        city: z.string().optional(),
        company_name: z.string().optional(),
        company_reg_code: z.string().optional(),
        company_vat_number: z.string().optional(),
        country: z.string().length(2).optional(),
        county: z.string().optional(),
        customer_id_number: z.string().optional(),
        customer_name: z.string().optional(),
        customer_phone: z.string().optional(),
        lang: z.union([z.string().length(2), z.literal("")]).optional(),
        post_index: z.string().optional(),
        site: z.string(),
        title: z.string(),
    })
    .openapi({ ref: "BillingAccountBaseFields" });

/** from:
 * https://gitlab.com/warrenio/platform/framework/payment-api/blob/b83aaea3b7886eb933c82522297e6e979ccfa586/qm_payment/models.py#L276-318
 */
export const RunningTotals = z
    .object({
        credit_amount: z.number(),
        credit_available: z.number(),
        discount_amount: z.number(),
        ongoing: z.number(),
        ongoing_negative_since: datetime_epoch_s.optional(),
        subtotal: z.number(),
        subtotal_resource_usage: z.number().optional(),
        total_before_tax: z.number(),
        total: z.number(),
        vat_tax: z.number(),
    })
    .openapi({ ref: "RunningTotals" });

export const BillingAccount = BillingAccountBaseFields.extend({
    additional_data: json_string.optional(),
    advertise_free_credit: z.boolean().optional(),
    allow_debt: z.boolean(),
    can_pay: z.boolean(),

    created: datetime_epoch_s,
    credit_amount: z.number(),
    discount_percentage: z.number(),
    eligible_promotions: z.array(BillingAccountEligiblePromotion).optional(),

    id: billing_account_id,

    is_active: z.boolean(),
    is_default: z.boolean(),
    is_deleted: z.boolean(),

    deleted_at: datetime_z.optional(),

    email: z.string(),

    is_recurring_payment_enabled: z.boolean(),

    open_level_override: z.string().optional(),
    paying_by_invoice: z.boolean(),

    precalc_credit_amount: z.number(),
    precalc_days_in_debt: z.number(),
    precalc_ongoing: z.number(),

    primary_card: BillingAccountCardData.optional(),

    recurring_payment_amount: z.number().optional(),
    recurring_payment_threshold: z.number().optional(),

    referral_code: z.string().optional(),
    referral_share_code: z.string(),
    reseller: z.string(),
    restriction_level: RestrictionLevel,
    running_totals: RunningTotals,
    send_invoice_email: z.boolean(),

    suspend_reason: z.string().optional(),
    unpaid_amount: z.number(),
    user_id,
    vat_percentage: z.number(),
}).openapi({ ref: "BillingAccount" });

const BillingAccountEditFields = BillingAccountBaseFields.partial().openapi({ ref: "BillingAccountEditFields" });

export const BillingAccountCreateFields = BillingAccountEditFields.extend({
    client_email: BillingAccount.shape.email.optional(),
    referral_code: BillingAccount.shape.referral_code.optional(),
    type: z.enum(["invoice", "other"]).optional(),
}).openapi({
    ref: "BillingAccountCreateFields",
});

export const BillingAccountUpdateFields = BillingAccountEditFields.extend({
    email: BillingAccount.shape.email.optional(),
})
    .partial()
    .openapi({
        ref: "BillingAccountUpdateFields",
    });

export const BillingListResponse = z.array(BillingAccount);

const RestrictionLevelClearTopupThreshold = z
    .object({
        restriction_level_clear_topup_threshold: z.number().optional(),
    })
    .openapi({ ref: "RestrictionLevelClearTopupThreshold" });

/** from:
 * https://gitlab.com/warrenio/platform/framework/charging-engine/blob/f7a18d0db8c9a9bc125dc2f0c8fea68eae5dfdf5/src/main/java/io/oye/pilw/chargingengine/controller/usage/dts/InvoiceLine.java#L24-L59
 */
export const BillingAccountUsage = z
    .object({
        // NB: This is NOT an actual UUID for eg. storage buckets
        uuid: not_a_uuid,
        owner_uuid: not_a_uuid,
        billing_account_id,
        user_id,
        hours: z.number(),
        uptime_types: z.array(z.string()),
        configurations: z.array(
            z.object({
                is_owned_by_service: z.boolean(),
                size_gigabytes: z.number().optional(),
                name: z.string().optional(),
                ipAddress: z.string().optional(),
                vm_name: z.string().optional(),
                size_GB: z.number().optional(),
                is_primary: z.boolean().optional(),
                is_attached: z.boolean().optional(),
                storage_pool_uuid: z.string().optional(),
                host_pool_uuid: z.string().optional(),
                display_name: z.string().optional(),
                cpus: z.number().optional(),
                ram_MB: z.number().optional(),
                os_name: z.string().optional(),
            }),
        ),
        description: z.string(),
        price: z.number(),
        cost: z.number(),
        price_unit: z.string(),
        // LOL, Java serializer mangled this name from "is_technical"
        _technical: z.boolean(),
    })
    .openapi({ ref: "BillingAccountUsage" });

export const BillingUsageListResponse = z.array(BillingAccountUsage);

const InvoiceTypes = z.enum(["cloud_services", "credit_topup", "credit_note"]).openapi({ ref: "InvoiceTypes" });

const InvoiceStatus = z.nativeEnum(InvoiceStatusEnum).openapi({ ref: "InvoiceStatus" });

const InvoiceId = z.union([z.literal("current"), z.number()]).openapi({ ref: "InvoiceId" });

const InvoiceRecord = z
    .object({
        amount: z.number(),
        created: datetime_epoch_s,
        descr: z.string().optional(),
        id: int,
        invoice_id: int,
        is_technical: z.boolean(),
        item_price: z.number(),
        location_slug: z.string().optional(),
        name: z.string(),
        qty_unit: z.string(),
        qty: z.number(),
        vat_amount: z.number(),
    })
    .openapi({ ref: "InvoiceRecord" });

export const BillingAccountInvoice = z
    .object({
        id: InvoiceId,
        is_current: z.boolean().optional(),
        name: z.string().optional(),
        account_snapshot: json_string.optional(),
        billing_account_id,
        created: datetime_epoch_s,
        discount_percentage: z.number(),
        due_date: datetime_epoch_s,
        padded_id: z.string().optional(),
        period_end: datetime_epoch_s,
        period_start: datetime_epoch_s,
        precalc_credit: z.number().optional(),
        precalc_discount_amount: z.number().optional(),
        precalc_subtotal: z.number().optional(),
        precalc_total: z.number().optional(),
        precalc_total_before_tax: z.number().optional(),
        precalc_vat_tax: z.number().optional(),
        records_list: z.array(InvoiceRecord),
        status: InvoiceStatus,
        totals: z.object({
            subtotal_resource_usage: z.number().optional(),
            subtotal: z.number(),
            discount_amount: z.number(),
            credit: z.number(),
            total_before_tax: z.number(),
            vat_tax: z.number(),
            total: z.number(),
        }),
        transaction_list: z
            .array(
                z.object({
                    additional_data: json_string.optional(),
                    amount: z.number(),
                    created: int,
                    id: int,
                    identifier: z.string().optional(),
                    is_success: z.boolean(),
                    payment_object_id: int.optional(),
                }),
            )
            .optional(),
        type: InvoiceTypes,
        vat_percentage: z.number(),
    })
    .openapi({ ref: "BillingAccountInvoice" });

export const BillingInvoicesListResponse = z.array(BillingAccountInvoice);

export const BillingAccountHistory = z
    .object({
        acting_user_id: user_id.optional(),
        amount: z.number(),
        billing_account_id,
        created: datetime_epoch_s,
        description: z.string(),
        id: int,
        reference: z.string().optional(),
    })
    .openapi({ ref: "BillingAccountHistory" });

export const BillingHistoryListResponse = z.array(BillingAccountHistory);

//#region Payment methods management & actual payment / top-up process

export const SaveMethodsBody = z
    .object({
        billing_account_id,
        method_ids: z.array(LinkMethodId),
    })
    .openapi({ ref: "SaveMethodsBody" });

export const AddCardBody = z
    .object({
        billing_account_id,
        token: z.string(),
    })
    .openapi({ ref: "AddCardBody" });

export const ApplyForInvoiceBody = z
    .object({
        billing_account_id,
    })
    .openapi({ ref: "ApplyForInvoiceBody" });

const CardPrehookResponse = z
    .object({
        id: z.string(),
        client_secret: z.string(),
        status: z.string(),
        usage: z.string(),
    })
    // Lots of unknown fields here, so we allow them
    .passthrough()
    .openapi({ ref: "CardPrehookResponse" });

const CardVerifyResponse = z
    .object({ card: BillingAccountCard, authorize_uri: z.string().url().optional() })
    .openapi({ ref: "CardVerifyResponse" });

const CardVerifyRequest = z
    .object({ payment_object_id: int, front_base_url: z.string() })
    .openapi({ ref: "CardVerifyRequest" });

//#region "/payment/link/send_data"
const LinkParamsBase = z
    .object({
        referrer_url: z.string().url(),
    })
    .openapi({ ref: "LinkParamsBase" });

const LinkParamsGeneric = LinkParamsBase.extend({
    paymentMethod: z.string(),
}).openapi({ ref: "LinkParamsGeneric" });

const LinkParamsOmise = LinkParamsGeneric.extend({
    source_id: z.string(),
    currency: z.string(),
}).openapi({ ref: "LinkParamsOmise" });

const LinkParams = z.union([LinkParamsOmise, LinkParamsGeneric]).openapi({ ref: "LinkParams" });

// NB: Since these types are not used as part of the OpenAPI request/response specs, we have to explicitly enumerate
// them so they would still be exported.
export const allLinkParamSchemas = { LinkParams };

export const LinkProcessorId = z
    .enum(["stripe", "stripe_wallet", "omise", "duitku", "sslcommerz", "adyen", "clictopay"])
    .openapi({ ref: "LinkProcessorId" });

export const LinkSendDataBody = z
    .object({
        amount: z.number(),
        billing_account_id,
        params: json_string.describe("JSON string containing `LinkParams*` types"),
        processor: LinkProcessorId,
    })
    .openapi({ ref: "LinkSendDataBody" });

// TODO: Add missing fields to these
const StripeSendDataResponse = z
    .object({
        front_redirect_method: z.string(),
        client_secret: z.string(),
        return_url: z.string().url(),
    })
    .openapi({ ref: "StripeSendDataResponse" });

const OmiseSendDataResponseBase = z
    .object({
        charge_id: z.string(),
        charge_status: z.string(),
        flow: z.string(),
        persistent_data_ref: z.string(),
    })
    .openapi({ ref: "OmiseSendDataBase" });

const OmiseRedirectSendDataResponse = OmiseSendDataResponseBase.extend({
    redirect_url: z.array(z.string().url()),
}).openapi({ ref: "OmiseRedirectSendDataResponse" });

const OmiseQrSendDataResponse = OmiseSendDataResponseBase.extend({
    qrcode_url: z.string().url(),
}).openapi({ ref: "OmiseQrSendDataResponse" });

// https://docs.duitku.com/api/en/#response-parameters
export const DuitkuRedirectSendDataResponse = z
    .object({
        redirect_url: z.string().url(),
        link_response: z.object({
            merchantCode: z.string(),
            reference: z.string(),
            paymentUrl: z.string(),
            vaNumber: z.string(),
            amount: z.number(),
            qrString: z.string(),
        }),
    })
    .openapi({ ref: "DuitkuRedirectSendDataResponse" });

export const SslcommerzRedirectSendDataResponse = z
    .object({
        redirect_url: z.string().url(),
        persistent_data_ref: z.string().optional(),
    })
    .openapi({ ref: "SslcommerzRedirectSendDataResponse" });

export const StripeWalletRedirectSendDataResponse = z
    .object({
        front_redirect_method: z.string(),
        client_secret: z.string(),
        return_url: z.string(),
        pay_intent_id: z.string(),
    })
    .openapi({ ref: "StripeWalletRedirectSendDataResponse" });

const GenericRedirectSendDataResponse = z
    .object({
        redirect_url: z.string().url(),
        link_response: z.unknown(),
    })
    .openapi({ ref: "GenericRedirectSendDataResponse" });

const LinkSendDataResponse = z
    .object({
        success: z.boolean(),
        error_msg: z.string(),
        data: z.union([
            StripeSendDataResponse,
            OmiseRedirectSendDataResponse,
            OmiseQrSendDataResponse,
            DuitkuRedirectSendDataResponse,
            SslcommerzRedirectSendDataResponse,
            StripeWalletRedirectSendDataResponse,
            GenericRedirectSendDataResponse,
        ]),
    })
    .openapi({ ref: "LinkSendDataResponse" });
//#endregion

//#region "/payment/credit/buy"
const GenericCreditBuyBody = z
    .object({
        billing_account_id,
        payment_object_id: int.describe("ID of the card"),
        amount: z.number(),
        on_session: z.boolean(),
    })
    .openapi({ ref: "GenericCreditBuyBody" });

const OmiseCreditBuyBody = GenericCreditBuyBody.extend({ front_base_url: z.string() }).openapi({
    ref: "OmiseCreditBuyBody",
});

export const CreditBuyBody = z.union([GenericCreditBuyBody, OmiseCreditBuyBody]).openapi({ ref: "CreditBuyBody" });

const CreditBuyResponseBase = z
    .object({
        success: z.boolean(),
        message: z.string(),
        data: z.unknown(),
        relay_props: z.object({}),
    })
    .openapi({ ref: "CreditBuyResponseBase" });

const StripeCreditBuyResponse = CreditBuyResponseBase.extend({
    data: z
        .object({
            client_secret: z.string(),
            payment_method: z.string(),

            amount: z.number(),
            status: z.string(),
        })
        // NB: Allow unknown fields for future compatibility
        .passthrough(),
}).openapi({ ref: "StripeCreditBuyResponse" });

const OmiseCreditBuyResponse = CreditBuyResponseBase.extend({
    data: z
        .object({
            id: z.string(),
            object: z.string(),
            status: z.string(),
        })
        .passthrough(),
    relay_props:
        // This is either an empty object or both of these fields, but TypeScript can not represent that nicely so just
        // use `.optional()`.
        z.object({
            authorize_uri: z.string().url().optional(),
            data_ref: z.string().optional(),
        }),
}).openapi({ ref: "OmiseCreditBuyResponse" });

const CreditBuyResponse = z
    .union([StripeCreditBuyResponse, OmiseCreditBuyResponse])
    .openapi({ ref: "CreditBuyResponse" });

const CreditBuyPosthookBody = z
    .object({
        billing_account_id,
        charge_id: z.string(),
        payment_object_id: int,
    })
    .openapi({ ref: "CreditBuyPosthookBody" });

const processor_name = z.string().openapi({ ref: "processor_name" });
const topup_amount = z.number().openapi({ ref: "topup_amount" });

const FinalAmountResponse = z
    .object({
        final_amount: z.number(),
    })
    .openapi({ ref: "FinalAmountResponse" });
//#endregion

const persistent_identifier = z.string().openapi({ ref: "persistent_identifier" });

const PaymentResultResponse = z
    .object({
        status: z.string(),
        message: z.string().optional(),
    })
    .openapi({ ref: "PaymentResultResponse" });

const PersistentDataResponse = z
    .object({
        billing_account_id,
        created: datetime_epoch_s,
        id: int,
        identifier: persistent_identifier,
        processor_method: ProcessorMethodId,
        data: json_string,
    })
    .openapi({ ref: "PersistentDataResponse" });

export const AutomatedTopUpEnableDisableBody = z
    .union([
        z.object({ is_recurring_payment_enabled: z.literal(false) }),
        z.object({
            recurring_payment_threshold: z.number(),
            recurring_payment_amount: z.number(),
            is_recurring_payment_enabled: z.literal(true),
        }),
    ])
    .openapi({ ref: "AutomatedTopUpEnableDisableBody" });

const AutomatedTopUpEnableDisableResponse = z
    .object({
        account: BillingAccount,
        payment_report: z
            .object({
                errors: z.array(z.unknown()),
                triggered: z.boolean(),
            })
            .optional(),
    })
    .openapi({ ref: "AutomatedTopUpEnableDisableResponse" });
//#endregion

const CampaignActivateResponse = z.object({ message: z.string() }).openapi({ ref: "CampaignActivateResponse" });

const PayInvoiceResponse = z
    .object({
        success: z.boolean(),
        message: z.string(),
    })
    .openapi({ ref: "PayInvoiceResponse" });

const billing_account_id_query = billing_account_id.openapi({
    example: 123,
    param: { name: "billing_account_id", in: "query" },
});

const billing_account_id_path = billing_account_id.openapi({
    example: 123,
    param: { name: "id", in: "path" },
});

const payment_object_id_query = int.openapi({ param: { name: "payment_object_id", in: "query" } });

const campaign_code_path = z.string().openapi({ param: { name: "campaign_code", in: "path" } });

export const billingPaths: ZodOpenApiPathsObject = {
    "/payment/billing_account/list": {
        get: {
            summary: "List billing accounts",
            tags: ["billing"],
            responses: {
                ...successResponse(BillingListResponse),
            },
        },
    },
    "/payment/billing_account": {
        post: {
            summary: "Create billing account",
            tags: ["billing"],
            requestBody: formBody(BillingAccountCreateFields),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
        put: {
            summary: "Update billing account",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            requestBody: formBody(BillingAccountUpdateFields),
            responses: {
                ...successResponse(BillingAccount),
            },
        },
        delete: {
            summary: "Delete billing account",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/payment/billing_account/set_default": {
        post: {
            summary: "Set billing account as default",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/payment/billing_account/{id}/campaign_activate/{campaign_code}": {
        post: {
            summary: "Activate campaign code",
            tags: ["billing"],
            parameters: [billing_account_id_path, campaign_code_path],
            responses: {
                ...successResponse(CampaignActivateResponse),
                ...errorResponse("Invalid campaign."),
            },
        },
    },
    "/payment/billing_account/{id}/recurring_payment": {
        put: {
            summary: "Enable or disable automated top up for billing account",
            tags: ["billing"],
            parameters: [billing_account_id_path],
            requestBody: formBody(AutomatedTopUpEnableDisableBody),
            responses: {
                ...successResponse(AutomatedTopUpEnableDisableResponse),
            },
        },
    },
    "/config/payment/restriction_level_clear_topup_threshold": {
        get: {
            summary: "Get restriction level clear topup threshold",
            tags: ["billing"],
            responses: {
                ...successResponse(RestrictionLevelClearTopupThreshold),
            },
        },
    },
    "/charging/usage": {
        get: {
            summary: "List billing account usage",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...successResponse(BillingUsageListResponse),
            },
        },
    },
    "/payment/invoice/list": {
        get: {
            summary: "List billing account invoices",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...successResponse(BillingInvoicesListResponse),
            },
        },
    },
    "/payment/invoice/pdf": {
        get: {
            summary: "Download invoice PDF",
            tags: ["billing"],
            parameters: [InvoiceId.openapi({ param: { name: "invoice_id", in: "query" } })],
            responses: {
                200: { content: { "application/pdf": {} } },
            },
        },
    },
    "/payment/pay_invoice": {
        post: {
            summary: "Pay invoice now",
            tags: ["billing"],
            parameters: [InvoiceId.openapi({ param: { name: "invoice_id", in: "query" } })],
            responses: {
                ...successResponse(PayInvoiceResponse),
            },
        },
    },
    "/payment/card/list": {
        get: {
            summary: "List billing account cards",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...successResponse(z.array(BillingAccountCard)),
            },
        },
    },
    "/payment/card": {
        post: {
            summary: "Add card to billing account",
            tags: ["billing"],
            requestBody: jsonBody(AddCardBody),
            responses: {
                ...successResponse(BillingAccountCard),
            },
        },
        delete: {
            summary: "Delete payment card",
            tags: ["billing"],
            parameters: [payment_object_id_query],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/payment/apply_for_invoice_payment": {
        post: {
            summary: "Apply for invoice payment",
            tags: ["billing"],
            requestBody: jsonBody(ApplyForInvoiceBody),
            responses: {
                ...successResponse(BillingAccountCard), // invoice payment is also stored as payment object in db (like credit cards)
            },
        },
    },
    "/payment/card/set_primary": {
        put: {
            summary: "Set card as primary",
            tags: ["billing"],
            parameters: [payment_object_id_query],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/payment/card/prehook": {
        post: {
            summary: "Pre-hook for card addition",
            tags: ["billing"],
            requestBody: jsonBody(z.object({ billing_account_id })),
            responses: {
                ...successResponse(CardPrehookResponse),
            },
        },
    },
    "/payment/card/verify": {
        post: {
            summary: "Verify card",
            tags: ["billing"],
            requestBody: jsonBody(CardVerifyRequest),
            responses: {
                ...successResponse(CardVerifyResponse),
            },
        },
    },
    "/payment/credit/list": {
        get: {
            summary: "List billing account history",
            tags: ["billing"],
            parameters: [billing_account_id_query],
            responses: {
                ...successResponse(BillingHistoryListResponse),
            },
        },
    },
    "/payment/link/save_methods": {
        post: {
            summary: "Save billing account payment methods",
            tags: ["billing"],
            requestBody: jsonBody(SaveMethodsBody),
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/payment/link/send_data": {
        post: {
            summary: "Start top-up process",
            tags: ["billing"],
            requestBody: jsonBody(LinkSendDataBody),
            responses: {
                ...successResponse(LinkSendDataResponse),
                ...errorResponse("Payment channel not available"),
            },
        },
    },
    "/payment/link/process_result": {
        get: {
            summary: "Poll for payment result",
            tags: ["billing"],
            parameters: [
                billing_account_id_query,
                persistent_identifier.openapi({ param: { name: "identifier", in: "query" } }),
            ],
            responses: {
                ...successResponse(PaymentResultResponse),
            },
        },
    },
    "/payment/persistent_data": {
        get: {
            summary: "Poll for webhook responses",
            tags: ["billing"],
            parameters: [
                billing_account_id_query,
                persistent_identifier.openapi({ param: { name: "identifier", in: "query" } }),
            ],
            responses: {
                ...successResponse(PersistentDataResponse),
            },
        },
    },
    "/payment/final_topup_amount": {
        get: {
            summary: "Get final topup amount",
            tags: ["billing"],
            parameters: [
                billing_account_id_query,
                processor_name.openapi({ param: { name: "processor", in: "query" } }),
                topup_amount.openapi({ param: { name: "amount", in: "query" } }),
            ],
            responses: {
                ...successResponse(FinalAmountResponse),
            },
        },
    },
    "/payment/credit/buy": {
        post: {
            summary: "Start credit purchase process",
            tags: ["billing"],
            requestBody: jsonBody(CreditBuyBody),
            responses: {
                ...successResponse(CreditBuyResponse),
            },
        },
    },
    "/payment/credit/buy/posthook": {
        post: {
            summary: "Post-hook for credit purchase",
            tags: ["billing"],
            requestBody: jsonBody(CreditBuyPosthookBody),
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
};
