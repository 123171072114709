import type { ZodOpenApiObject, ZodOpenApiPathsObject } from "zod-openapi";
import { accessPaths } from "./spec/access.ts";
import { BillingAdditionalData, allLinkParamSchemas, billingPaths } from "./spec/billing.ts";
import { campaignsPaths } from "./spec/campaigns.ts";
import { configPaths } from "./spec/config.ts";
import { locationPaths } from "./spec/locations.ts";
import { networkPaths } from "./spec/network.ts";
import { hostPoolPaths } from "./spec/pools.ts";
import { pricingPaths } from "./spec/pricing.ts";
import { referralPaths } from "./spec/referral.ts";
import { servicePaths } from "./spec/services.ts";
import { storagePaths } from "./spec/storage.ts";
import { trackingPaths } from "./spec/tracking.ts";
import { userPaths } from "./spec/user.ts";
import { vmPaths } from "./spec/vm.ts";

export const paths = {
    ...locationPaths,
    ...vmPaths,
    ...servicePaths,
    ...networkPaths,
    ...pricingPaths,
    ...storagePaths,
    ...accessPaths,
    ...billingPaths,
    ...hostPoolPaths,
    ...userPaths,
    ...configPaths,
    ...campaignsPaths,
    ...referralPaths,
    ...trackingPaths,
} satisfies ZodOpenApiPathsObject;

export const api: ZodOpenApiObject = {
    openapi: "3.1.0",
    info: {
        title: "Warren API",
        version: "1.0.0",
        "x-logo": {
            url: "https://api.warren.io/images/logo.svg",
            href: "http://warren.io/",
            altText: "Warren logo",
        },
    },
    servers: [{ url: "https://api.warren.io/v1" }],
    security: [{ apikey: [] }],
    components: {
        securitySchemes: {
            apikey: {
                type: "apiKey",
                in: "header",
                name: "apikey",
                // description: "API key",
            },
        },
        // Extra types that are not referenced by any paths/responses
        schemas: {
            BillingAdditionalData,
            ...allLinkParamSchemas,
        },
    },
    paths,
    tags: [
        {
            name: "location",
            "x-displayName": "Locations",
        },
        {
            name: "vm",
            description: "Virtual Machines",
            "x-displayName": "VMs",
        },
        {
            name: "services",
            description: "Managed Services",
            "x-displayName": "Services",
        },
        {
            name: "vpc",
            "x-displayName": "Private Networks",
        },
        {
            name: "ip_address",
            "x-displayName": "Floating IPs",
        },
        {
            name: "load_balancer",
            "x-displayName": "Load Balancers",
        },
        {
            name: "storage",
            "x-displayName": "Storage",
        },
        {
            name: "token",
            description: "API Tokens",
            "x-displayName": "Tokens",
        },
        {
            name: "delegation",
            description: "Access Delegation",
            "x-displayName": "Delegation",
        },
        {
            name: "pricing",
            "x-displayName": "Pricing",
        },
        {
            name: "billing",
            "x-displayName": "Billing",
        },
        {
            name: "host_pool",
            "x-displayName": "Host Pool",
        },
        {
            name: "impersonation",
            "x-displayName": "Impersonation",
        },
        {
            name: "user",
            "x-displayName": "User",
        },
        {
            name: "config",
            "x-displayName": "Configuration",
        },
    ],
};
