import type { ApiErrorResponse } from "@warrenio/api-spec/spec.oats.gen";
import type { z } from "zod";

// NB: Put exceptions in a separate file so they can be imported without importing the entire API module.
// Good for bundling efficiency and avoiding circular dependencies.

export class UnauthenticatedError extends Error {
    name = "UnauthenticatedError";

    constructor(message = "No API key set") {
        super(message);
    }
}

export class ApiConnectionError extends Error {
    name = "ApiConnectionError";

    constructor(cause: Error) {
        super("Failed to connect to server", { cause });
    }
}

//#region ApiError and subclasses

/** Extra information about an error */
export interface ApiErrorMeta {
    /** HTTP status code */
    status: number;
    /** Request URL */
    url: string;
    /** x-warren-correlation-id header */
    correlationId?: string | null;
}

/** Base class for all actual API errors (with a backend-provided message) */
export abstract class ApiError<T = unknown> extends Error {
    name = "ApiError";

    constructor(
        public readonly error: T,
        public readonly meta: ApiErrorMeta,
        public readonly errorMessage: string,
    ) {
        let message = errorMessage;
        const { status, correlationId } = meta;

        if (correlationId != null) {
            message += ` [${correlationId}]`;
        }
        message += ` (HTTP ${status})`;

        super(message);
    }
}

/** Unknown type of error (eg. HTTP 500 Internal Server error) */
export class ApiUnknownError extends ApiError<unknown> {
    name = "ApiUnknownError";
}

export interface DevErrorInfo {
    message: string;
    stack?: unknown;
}

/** Error messages related to mock API failures etc. */
export class ApiDevError extends ApiError<DevErrorInfo> {
    name = "ApiDevError";

    constructor(error: DevErrorInfo, meta: ApiErrorMeta) {
        super(error, meta, error.message);
    }
}

/** "Specific" response from eg. the API mutation endpoints */
export class ApiStandardError extends ApiError<ApiErrorResponse> {
    name = "ApiStandardError";

    constructor(error: ApiErrorResponse, meta: ApiErrorMeta) {
        super(error, meta, error.errors.Error);
    }
}

export interface GeneralErrorInfo {
    message: string;
}

/** General API errors, such as authentication or other failures */
export class ApiGeneralError extends ApiError<GeneralErrorInfo> {
    name = "ApiGeneralError";

    constructor(error: GeneralErrorInfo, meta: ApiErrorMeta) {
        super(error, meta, error.message);
    }
}

/** 403 Forbidden */
export class ApiCredentialsError extends ApiGeneralError {
    name = "ApiCredentialsError";
}

//#endregion ApiError and subclasses

/** Failure validating that the response matches the API spec. */
export class ApiZodValidationError extends Error {
    name = "ApiZodValidationError";

    constructor(
        public readonly url: string,
        public readonly cause: z.ZodError,
    ) {
        super(cause.message, { cause });
    }
}
