import { z } from "zod";
import {
    extendZodWithOpenApi,
    type oas31,
    type ZodOpenApiMediaTypeObject,
    type ZodOpenApiRequestBodyObject,
    type ZodOpenApiResponsesObject,
} from "zod-openapi";

extendZodWithOpenApi(z);

/** A template string literal that de-indents. */
export function ind(template: TemplateStringsArray, ...subs: any[]): string {
    const result = template[0].replace(/\n[ \t]*/g, "\n").replace(/^\n/, "");
    return result + subs.map((s, i) => s + template[i + 1]).join("");
}

/** Remove OpenAPI `ref` so eg. the description or example can be overridden. */
export function unref<T extends z.ZodTypeAny>(type: T) {
    return type.openapi({ ref: undefined });
}

/** Convert a string to a boolean. For use in form bodies (similarly to eg. `zfd.numeric()`) */
export function formBoolean<T extends z.ZodTypeAny>(schema: T) {
    return z.preprocess((arg) => {
        if (arg === "true" || arg === "1") {
            return true;
        }
        if (arg === "false" || arg === "0" || arg === "") {
            return false;
        }
        return arg;
    }, schema);
}

export function successResponse<T extends z.Schema>(
    schema: T,
    description = "Successful response",
): ZodOpenApiResponsesObject {
    return {
        "200": {
            description,
            content: { "application/json": { schema } },
        },
    };
}

export const ErrorSchema = z
    .object({
        errors: z.object({ Error: z.string().describe("Error message") }),
    })
    .describe("Common error type")
    .openapi({ ref: "ApiErrorResponse" });

export function errorResponse(example?: string): ZodOpenApiResponsesObject {
    return customErrorResponse(ErrorSchema, example ? { errors: { Error: example } } : undefined);
}

export function customErrorResponse<T extends z.Schema>(
    schema: T,
    example?: unknown,
    description = "Error response",
): ZodOpenApiResponsesObject {
    const extra: ZodOpenApiMediaTypeObject | undefined = example ? { example } : undefined;
    return {
        "500": {
            description,
            content: {
                "application/json": { schema, ...extra },
            },
        },
    };
}

/** Utility to create a standard OpenAPI form body from a Zod schema */
export function formBody<T>(schema: z.Schema<T>, extra?: Partial<oas31.MediaTypeObject>): ZodOpenApiRequestBodyObject {
    return {
        required: true,
        content: {
            "application/x-www-form-urlencoded": { schema, ...extra },
        },
    };
}

export function jsonBody<T>(schema: z.Schema<T>, extra?: Partial<oas31.MediaTypeObject>): ZodOpenApiRequestBodyObject {
    return {
        required: true,
        content: {
            "application/json": { schema, ...extra },
        },
    };
}
