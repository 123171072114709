import isChromatic from "chromatic/isChromatic";
import invariant from "tiny-invariant";

const BASE_URL = import.meta.env.BASE_URL;
export const basepath = BASE_URL === "/" ? "" : BASE_URL || "/app";

if (basepath.endsWith("/")) {
    console.error("basepath %o should not end with a slash", basepath);
}

/** NB: Chromatic uses a different hostname for every snapshot, so we need to use a constant URL so the snapshots would not constantly change */
export function getVisibleSiteBaseUrl() {
    return isChromatic() ? "https://example.com" : window.location.origin;
}

export function getFullUrl(path: string) {
    return `${window.location.origin}${getRelativeUrl(path)}`;
}

export function getRelativeUrl(path: string) {
    invariant(path.startsWith("/"), "Path must start with a slash");
    return `${basepath}${path}`;
}
