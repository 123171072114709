import { z, type ZodRawShape } from "zod";
import { zfd } from "zod-form-data";
import { extendZodWithOpenApi, type ZodOpenApiPathsObject } from "zod-openapi";
import { errorResponse, formBody, ind, jsonBody, successResponse, unref } from "../util.ts";
import {
    billing_account_id,
    created_at,
    int,
    ipv4,
    ipv6,
    optional_uuid_param,
    updated_at,
    user_id,
    uuid,
    vm_uuid,
} from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

//#region Common Virtual Machine fields
export const disk_size_gb = int.describe("Disk size (GB)").min(1).openapi({ ref: "size_gb", example: 10 });
export const vcpu = int.describe("Number of vCPUs").min(1).openapi({ example: 2 });
export const memory = int.describe("Memory size (MB)").min(1).openapi({ example: 2048 });
export const username = z.string().describe("Username for login").openapi({ example: "neo" });
export const password = z.string().describe("Password for login").openapi({ example: "hunter2" });
//#endregion

export const DiskType = z.enum(["n/a"]).describe("Disk type").openapi({ ref: "DiskType" });

export const ReplicaType = z.enum(["snapshot", "backup"]).describe("Replica type").openapi({ ref: "ReplicaType" });

const VirtualMachineStorageBase = z
    .object({
        uuid: uuid,
        size: disk_size_gb,
        created_at,
        updated_at: updated_at.optional(),
        pool: z.string(),
        type: z.string(),
    })
    .openapi({ ref: "VirtualMachineStorageBase" });

const VirtualMachineStorageReplica = VirtualMachineStorageBase.extend({
    master_uuid: uuid,
    type: ReplicaType,
}).openapi({ ref: "VirtualMachineStorageReplica" });

const VirtualMachineStorage = VirtualMachineStorageBase.extend({
    primary: z.boolean().describe("This is the primary (ie. first, boot) disk"),
    replica: z.array(VirtualMachineStorageReplica).optional(),
    shared: z.boolean(),
    user_id,
    name: z.string().openapi({ example: "vda" }),
    type: DiskType,
}).openapi({ ref: "VirtualMachineStorage" });

export const VmStatusEnum = z
    .enum(["creating", "running", "stopping", "stopped", "deleted"])
    .describe("Virtual machine state")
    .openapi({
        example: "running",
        ref: "VmStatusEnum",
    });

const VirtualMachineFields = {
    backup: z.boolean().describe("Backup type"),
    billing_account: billing_account_id,
    created_at,
    current_pool_name: z.string().optional().describe("Current resource pool name"),
    current_pool_uuid: uuid.optional().describe("Current resource pool ID"),
    description: z.string().describe("Virtual Machine description"),
    designated_pool_name: z.string().optional().describe("Designated resource pool name"),
    designated_pool_uuid: uuid.optional().describe("Designated resource pool ID"),
    hostname: z.string().min(1).describe("Hostname"),
    mac: z
        .string()
        .regex(/^[0-9a-f]{2}(:[0-9a-f]{2}){5}$/)
        .describe("MAC address")
        .openapi({ example: "00:00:5e:00:53:01" }),
    memory,
    name: z.string().describe("Virtual Machine name").openapi({ example: "PLATO" }),
    nic_iftune: z
        .object({
            average_kbps: z.number(),
            burst_kb: z.number(),
            peak_kbps: z.number(),
        })
        .optional(),
    os_name: z.string().describe("Operating System name").openapi({ example: "debian" }),
    os_version: z.string().describe("Operating System version").openapi({ example: "11" }),
    license_type: z.string().nullable().optional().describe("License type"),
    private_ipv4: ipv4.describe("Private IPv4 address of the virtual machine"),
    public_ipv6: ipv6.optional().describe("Public IPv6 address"),
    status: VmStatusEnum,
    storage: z.array(VirtualMachineStorage),
    updated_at,
    user_id: unref(user_id).describe("Creating user ID"),
    username,
    uuid: vm_uuid,
    vcpu,
} as const satisfies ZodRawShape;

export const VirtualMachine = z.object(VirtualMachineFields).openapi({ ref: "VirtualMachine" });

export const VmCreateBody = z
    .object({
        name: VirtualMachineFields.name,
        description: VirtualMachineFields.description,
        disks: zfd.numeric(
            unref(disk_size_gb).optional().describe("Disk size (GB). Required if `disk_uuid` is not set."),
        ),
        os_name: VirtualMachineFields.os_name,
        os_version: VirtualMachineFields.os_version,
        username,
        password,
        ram: zfd.numeric(memory),
        vcpu: zfd.numeric(vcpu),
        reserve_public_ip: z.coerce.boolean().optional().default(true).describe("Reserve a public IP address"),
        billing_account_id: zfd.numeric(billing_account_id.optional()),
        designated_pool_uuid: unref(VirtualMachineFields.designated_pool_uuid)
            .optional()
            .describe("Resource pool to allocate this VM into"),
        network_uuid: optional_uuid_param.describe("Network ID to attach"),
        disk_uuid: optional_uuid_param.describe("Disk ID to attach"),
        public_key: z.string().optional().describe("SSH public key").openapi({ example: "ssh-rsa..." }),
        // XXX: Use a separate VmCreate... type for starting from a snapshot?
        source_replica: optional_uuid_param.describe("Source virtual machine's disk snapshot ID"),
        source_uuid: optional_uuid_param.describe("Source virtual machine ID"),
        cloud_init: z.string().optional().describe("Cloud config data for cloud-init (string containing JSON)"),
    })
    .openapi({ ref: "VmCreateBody" });

export const VmModifyBody = z
    .object({
        uuid: vm_uuid,
        name: VirtualMachineFields.name.optional(),
        ram: zfd.numeric(memory.optional()),
        vcpu: zfd.numeric(vcpu.optional()),
    })
    .openapi({ ref: "VmModifyBody" });

export const VmStartBody = z.object({ uuid: vm_uuid }).openapi({ ref: "VmStartBody" });

export const VmReplicaCreateBody = z.object({ uuid: vm_uuid }).openapi({ ref: "VmReplicaCreateBody" });
export const VmReplicaDeleteBody = z
    .object({ replica_uuid: uuid, type: ReplicaType })
    .openapi({ ref: "VmReplicaDeleteBody" });
export const VmReplicaRebuildBody = z
    .object({
        replica_uuid: uuid,
        uuid: vm_uuid,
        type: ReplicaType,
    })
    .openapi({ ref: "VmReplicaRebuildBody" });

export const VmBackupEnableDisableBody = z.object({ uuid: vm_uuid }).openapi({ ref: "VmBackupEnableDisableBody" });

export const VmStopBody = z
    .object({ uuid: vm_uuid, force: z.boolean().describe("Stop forcefully") })
    .openapi({ ref: "VmStopBody" });

export const VmCloneBody = z.object({ uuid: vm_uuid, name: VirtualMachineFields.name }).openapi({ ref: "VmCloneBody" });

export const VmDeleteBody = z.object({ uuid: vm_uuid }).openapi({ ref: "VmDeleteBody" });

export const VmAddDiskBody = z
    .object({
        uuid: vm_uuid,
        size_gb: disk_size_gb,
    })
    .openapi({ ref: "VmAddDiskBody" });

export const VmResizeDiskBody = z
    .object({
        uuid: vm_uuid,
        disk_uuid: uuid.describe("Disk UUID"),
        size_gb: disk_size_gb,
        check_vm_state: z.boolean().describe("Check VM state before resizing").optional(),
    })
    .openapi({ ref: "VmResizeDiskBody" });

export const VmDeleteDiskBody = z
    .object({ uuid: vm_uuid, storage_uuid: uuid.describe("") })
    .openapi({ ref: "VmDeleteDiskBody" });

export const VmChangePasswordBody = z
    .object({ uuid: vm_uuid, username, password })
    .openapi({ ref: "VmChangePasswordBody" });

export const VmStartInRescueBody = z.object({ uuid: vm_uuid }).openapi({ ref: "VmStartInRescueBody" });

export const VmReinstallBody = z
    .object({ uuid: vm_uuid, os_name: VirtualMachineFields.os_name, os_version: VirtualMachineFields.os_version })
    .openapi({ ref: "VmReinstallBody" });

export const VmImageVersion = z
    .object({
        os_version: z.string().describe("Operating System version"),
        display_name: z.string().describe("Display name"),
        published: z.boolean().optional(),
    })
    .openapi({ ref: "VmImageVersion" });
export const VmImage = z
    .object({
        os_name: z.string().describe("Operating System name"),
        display_name: z.string().describe("Display name"),
        ui_position: int.describe("UI position"),
        license_type: z.string().optional(), // TODO: Maybe needs to be .enum(["WINDOWS"])
        is_default: z.boolean().describe("Is default"),
        is_app_catalog: z.boolean().describe("Is app catalog"),
        icon: z.string().describe("base64-encoded icon").openapi({ contentEncoding: "base64" }),
        versions: z.array(VmImageVersion),
    })
    .openapi({ ref: "VmImage" });

export const VmImagesResponse = z.array(VmImage).openapi({ ref: "VmImagesResponse" });

const example_uuid = "123e4567-e89b-12d3-a456-426614174000";

export const VmListResponse = z.array(VirtualMachine);

export const VmChangeBillingAccountBody = z
    .object({
        uuid,
        billing_account_id,
        // Backend does not check this
        resource_type: z.enum(["vm"]).describe("**Unused**").optional(),
    })
    .openapi({ ref: "ChangeResourceBillingAccountBody" });

//#region VM parameters

const VmParameterType = z.enum(["string", "integer"]).describe("Parameter type").openapi({ ref: "VmParameterType" });
const VmParameterConstraint = z
    .enum(["enum", "range", "regexp"])
    .describe("Parameter constraint type")
    .openapi({ ref: "VmParameterConstraint" });

export const VmParameter = z
    .object({
        parameter: z.string().describe("Parameter name"),
        description: z.string().describe("Parameter description"),
        mandatory: z.boolean(),
        type: VmParameterType,
        constraint: VmParameterConstraint.optional(),
        expression: z.string().optional(),
        ignore_for: z.string().optional(),
        min: int.optional(),
        max: int.optional(),
        limited_by: z.string().optional(),
        limits: z
            .array(
                z.object({
                    os_name: z.string(),
                    values: z.array(z.string()).optional(),
                    min: int.optional(),
                    mandatory: z.boolean().optional(),
                    description: z.string().optional(),
                }),
            )
            .optional(),
        values: z.array(z.string()).optional(),
    })
    .openapi({ ref: "VmParameter" });

export const VmParameterResponse = z.array(VmParameter).openapi({ ref: "VmParameterResponse" });
const SuccessTrueResponse = z.object({ success: z.boolean() });

//#endregion

export const vmPaths: ZodOpenApiPathsObject = {
    "/api/parameters/vm": {
        get: {
            summary: "Get list of available VM parameters",
            tags: ["vm"],
            responses: {
                ...successResponse(VmParameterResponse),
            },
        },
    },
    "/config/vm_images": {
        get: {
            summary: "Get list of available VM images",
            tags: ["vm"],
            responses: {
                ...successResponse(VmImagesResponse),
            },
        },
    },
    "/{location}/user-resource/vm": {
        post: {
            summary: "Create a new virtual machine",
            description: ind`\`billing_account_id\` is optional if using an API token that is restricted to one billing account.`,
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmCreateBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Created virtual machine"),
                ...errorResponse(
                    "Compute resources temporarily unavailable due to high demand. If possible, choose a different region or location.",
                ),
            },
        },
        patch: {
            summary: "Modify a virtual machine",
            description: ind`Change the name, or resize the RAM and vCPU amount of a virtual machine
                (only when the machine is in the stopped state).`,
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmModifyBody, {
                examples: {
                    "Update name": {
                        description: "Update the name of the virtual machine",
                        value: { uuid: example_uuid, name: "New name" },
                    },
                    Resize: {
                        description: "Change the RAM and vCPUs of the virtual machine",
                        value: { uuid: example_uuid, ram: 4096, vcpu: 2 },
                    },
                },
            }),
            responses: {
                ...successResponse(VirtualMachine, "Success: Updated virtual machine"),
                ...errorResponse("Failed to update virtual machine"),
            },
        },
        delete: {
            summary: "Delete a virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: jsonBody(VmDeleteBody),
            responses: {
                ...successResponse(SuccessTrueResponse, "Success: Virtual machine deleted"),
                ...errorResponse("Failed to delete virtual machine"),
            },
        },
    },

    "/{location}/user-resource/vm/backup": {
        post: {
            summary: "Enable or disable backups for a virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmBackupEnableDisableBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Backup enabled/disabled"),
                ...errorResponse("Failed to enable/disable backup"),
            },
        },
    },

    "/{location}/user-resource/vm/clone": {
        post: {
            summary: "Clone virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmCloneBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine cloned"),
                ...errorResponse("Failed to clone virtual machine"),
            },
        },
    },
    "/{location}/user-resource/vm/list": {
        get: {
            summary: "Get list of virtual machines",
            tags: ["vm"],
            parameters: [params.location, params.billing_account_id],
            responses: {
                ...successResponse(VmListResponse),
            },
        },
    },

    "/{location}/user-resource/vm/rebuild": {
        post: {
            summary: "Discard virtual machine's current storage state and restore it from the specified replica",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmReplicaRebuildBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine rebuilt"),
                ...errorResponse("Failed to rebuild virtual machine"),
            },
        },
    },
    "/{location}/user-resource/vm/replica": {
        post: {
            summary: "Create a snapshot of a virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmReplicaCreateBody),
            responses: {
                ...successResponse(VirtualMachineStorage, "Success: Created snapshot of virtual machine"),
                ...errorResponse("Failed to create snapshot of virtual machine"),
            },
        },
        delete: {
            summary: "Delete a snapshot of a virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: jsonBody(VmReplicaDeleteBody),
            responses: {
                ...successResponse(SuccessTrueResponse, "Success: Snapshot deleted"),
                ...errorResponse("Failed to delete snapshot"),
            },
        },
    },
    "/{location}/user-resource/vm/rescue_start": {
        post: {
            summary: "Start VM in rescue mode",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmStartInRescueBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine started in rescue mode"),
            },
        },
    },
    "/{location}/user-resource/vm/reinstall": {
        post: {
            summary: "Reinstall VM",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmReinstallBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine reinstalled"),
            },
        },
    },
    "/{location}/user-resource/vm/start": {
        post: {
            summary: "Start virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmStartBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine started"),
                ...errorResponse("Failed to start virtual machine"),
            },
        },
    },
    "/{location}/user-resource/vm/stop": {
        post: {
            summary: "Stop virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmStopBody),
            responses: {
                ...successResponse(VirtualMachine, "Success: Virtual machine stopped"),
                ...errorResponse("Failed to stop virtual machine"),
            },
        },
    },
    "/{location}/user-resource/vm/storage": {
        delete: {
            summary: "Delete a virtual machine disk",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmDeleteDiskBody),
            responses: {
                ...successResponse(SuccessTrueResponse),
                ...errorResponse("Failed to delete disk from VM"),
            },
        },
        patch: {
            summary: "Resize a virtual machine disk",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmResizeDiskBody),
            responses: {
                ...successResponse(VirtualMachineStorage, "Success: VM disk resized"),
                ...errorResponse("Failed to resize disk on VM"),
            },
        },
        post: {
            summary: "Add a new disk to a virtual machine",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmAddDiskBody),
            responses: {
                ...successResponse(VirtualMachineStorage, "Success: VM disk added"),
                ...errorResponse("Failed to add disk to VM"),
            },
        },
    },
    "/{location}/user-resource/vm/user": {
        patch: {
            summary: "Change the password of a virtual machine",
            description: ind`Set a new password for an existing user on the virtual machine.
            The VM must be running, otherwise the password cannot be changed and an error will be returned.`,
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmChangePasswordBody),
            responses: {
                ...successResponse(SuccessTrueResponse),
            },
        },
    },
    "/{location}/user-resource/resource_billing": {
        post: {
            summary: "Change VM's billing account",
            tags: ["vm"],
            parameters: [params.location],
            requestBody: formBody(VmChangeBillingAccountBody),
            responses: {
                ...successResponse(VirtualMachine),
            },
        },
    },
};
