import { z } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, successResponse } from "../util.ts";

extendZodWithOpenApi(z);

//#region Tracking
export const GoogleTagManagerKey = z
    .object({
        gtm_key: z.string(),
    })
    .openapi({ ref: "GoogleTagManagerKey" });
//#endregion

const gtm_key_path = z.string().openapi({ param: { name: "gtm_key", in: "query" } });

export const trackingPaths: ZodOpenApiPathsObject = {
    "/config/ui/gtm_key": {
        get: {
            tags: ["tracking"],
            summary: "Get Google Tag Manager key",
            responses: {
                ...successResponse(GoogleTagManagerKey),
                ...errorResponse(`Failed to get Google Tag Manager key`),
            },
        },
        put: {
            summary: "Update Google Tag Manager key",
            tags: ["tracking"],
            //config/ui/gtm_key?gtm_key=GTM-xxxxxxx
            parameters: [gtm_key_path],
            responses: {
                ...successResponse(GoogleTagManagerKey),
                ...errorResponse("Failed to update Google Tag Manager key"),
            },
        },
    },
};
