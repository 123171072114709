import { z, type ZodRawShape } from "zod";
import { extendZodWithOpenApi, type ZodOpenApiPathsObject } from "zod-openapi";
import { jsonBody, successResponse } from "../util.ts";
import {
    billing_account_id,
    created_at,
    datetime,
    ipv4,
    simpleSuccessResponse,
    updated_at,
    user_id,
    uuid,
} from "./common.ts";
import * as params from "./params.js";
import { ReplicaType, VmStatusEnum, disk_size_gb, memory, vcpu } from "./vm.js";

extendZodWithOpenApi(z);

const ManagedServicePriceTypeEnum = z
    .enum([
        "CPU",
        "RAM",
        // Only used for price multiplier for main BLOCK_STORAGE
        "STORAGE",
        "OBJECT_STORAGE",
        "BLOCK_STORAGE",
        "BLOCK_STORAGE_SNAPSHOT",
        "BLOCK_STORAGE_BACKUP",
        "WINDOWS_LICENSE",
        "LICENSE",
        "UNASSIGNED_FLOATING_IP",
        "ASSIGNED_FLOATING_IP",
    ])
    .describe("Managed service price resource type")
    .openapi({ example: "CPU", ref: "ManagedServicePriceTypeEnum" });

const UptimeName = z.enum(["main", "block", "snapshot", "backup", "windows"]).openapi({ ref: "UptimeName" });

const CamelCasePrice = z
    .object({
        priceMultiplier: z.number(),
        resourceType: ManagedServicePriceTypeEnum,
        serviceNameInUptime: UptimeName.optional(),
    })
    .openapi({ ref: "CamelCasePrice" });

const SnakeCasePrice = z
    .object({
        price_multiplier: z.number(),
        resource_type: ManagedServicePriceTypeEnum,
    })
    .openapi({ ref: "SnakeCasePrice" });

/**
 * NB: Prices are duplicated in two different formats
 * See:
 *      https://oye.slack.com/archives/G012FAQL06S/p1670247135865529
 *      https://warrenio.atlassian.net/browse/WAR-4166
 */
export const ManagedServicesPricesBody = z
    .array(z.union([CamelCasePrice, SnakeCasePrice]))
    .openapi({ ref: "ManagedServicesPricesBody" });

export const ManagedServicesVmStorageReplica = z
    .object({
        uuid,
        created_at,
        size: disk_size_gb,
        type: ReplicaType,
    })
    .openapi({ ref: "ManagedServicesVmStorageReplica" });

export const ManagedServicesVmStorage = z
    .object({
        uuid,
        primary: z.boolean(),
        size: disk_size_gb,
        replica: z.array(ManagedServicesVmStorageReplica),
    })
    .openapi({ ref: "ManagedServicesVmStorage" });

const ManagedServiceResourceType = z
    .enum(["vm", "virtual_ip", "network", "kong_token"])
    .openapi({ example: "vm", ref: "ManagedServiceResourceType" });

export const ManagedServicesVmBody = z
    .object({
        resource_allocation: z.object({
            address: ipv4.optional(),
            memory: memory.optional(),
            vcpu: vcpu.optional(),
            status: VmStatusEnum.optional(),
            storage: z.array(ManagedServicesVmStorage).optional(),
        }),
        resource_id: z.string(),
        resource_location: z.string().optional(),
        resource_type: ManagedServiceResourceType,
    })
    .openapi({ ref: "ManagedServicesVmBody" });

/**
 * https://gitlab.com/warrenio/platform/framework/user-resource-interface/blob/af089adac60c1a4aaf0cc9e417a8ece54a747622/api/resources/service/package_setup.py#L33-41
 * https://gitlab.com/warrenio/platform/framework/user-resource-interface/blob/11bf6cd704c279de1ffb43941a741ffad0ddb383/api/resources/service_package_resources.py#L552-605
 */
const ManagedServiceEnum = z
    .enum(["mariadb", "postgresql", "kubernetes", "self-service-dummy-testing"])
    .openapi({ example: "mariadb", ref: "ManagedServiceEnum" });

const ServicePropertiesBase = z
    .object({
        location: z.string(),
    })
    .openapi({ ref: "ServicePropertiesBase" });

const KubernetesProperties = ServicePropertiesBase.extend({
    cluster_name: z.string(),
    spec: z.object({
        hibernation: z.object({
            enabled: z.boolean(),
        }),
        kubernetes: z.any(),
        maintenance: z.any(),
        networking: z.any(),
        provider: z.any(),
    }),
    status: z.object({
        hibernated: z.boolean(),
        conditions: z.any(),
        constraints: z.any(),
        gardener: z.any(),
        lastOperation: z.any(),
        observedGeneration: z.any(),
        retryCycleStartTime: z.any(),
        seedName: z.any(),
        technicalID: z.any(),
        uid: z.any(),
        clusterIdentity: z.any(),
        advertisedAddresses: z.any(),
        lastErrors: z.any(),
        lastMaintenance: z.any(),
    }),
});

const DbServiceProperties = ServicePropertiesBase.extend({
    service_ip: ipv4,
    sql_user: z.string(),
    port: z.number(),
}).openapi({ ref: "DbServiceProperties" });

const ManagedServiceBaseFields = {
    billing_account_id: billing_account_id,
    created_at,
    deleted_at: datetime.nullable().optional(),
    display_name: z.string(),
    is_deleted: z.boolean(),
    is_multi_node: z.boolean(),
    prices: ManagedServicesPricesBody,
    properties: ServicePropertiesBase,
    resources: z.array(ManagedServicesVmBody),
    service: ManagedServiceEnum, // TODO: proper service enum
    status: z.string(),
    updated_at: updated_at.optional(),
    user_id,
    uuid,
    version: z.string(),
} as const satisfies ZodRawShape;

const ManagedServiceBase = z.object(ManagedServiceBaseFields).openapi({ ref: "ManagedServiceBase" });

const ManagedDbService = ManagedServiceBase.extend({
    service: z.union([z.literal("mariadb"), z.literal("postgresql")]),
    properties: DbServiceProperties,
}).openapi({ ref: "ManagedDbService" });

const ManagedKubernetesService = ManagedServiceBase.extend({
    service: z.literal("kubernetes"),
    properties: KubernetesProperties,
}).openapi({ ref: "ManagedKubernetesService" });

const _ManagedDummyService = ManagedServiceBase.extend({
    service: z.literal("self-service-dummy-testing"),
}).openapi({ ref: "ManagedDummyService" });

export const ManagedService = z
    .union([
        ManagedDbService,
        ManagedKubernetesService,
        // NB: Exclude dummy service since it makes type check conditions in the UI more difficult
        // _ManagedDummyService
    ])
    .openapi({ ref: "ManagedService" });

export const ManagedServiceCreateBody = z
    .object({
        billing_account_id,
        display_name: ManagedServiceBaseFields.display_name,
        is_multi_node: ManagedServiceBaseFields.is_multi_node,
        package_parameters: z.object({
            location: z.string(),
            network_uuid: uuid,
        }),
        reserve_public_ip: z.coerce.boolean().optional().default(true).describe("Reserve a public IP address"),
        service: ManagedServiceBaseFields.service,
        version: ManagedServiceBaseFields.version,
        vm_cpu: vcpu,
        vm_disk_gb: disk_size_gb,
        vm_ram: memory,
    })
    .openapi({ ref: "ManagedServiceCreateBody" });

export const ServiceListResponse = z.array(ManagedService);

export const ServiceImage = z
    .object({
        created_at,
        description: z.string(),
        display_name: z.string().describe("Display name"),
        icon: z.string().describe("base64-encoded icon").openapi({ contentEncoding: "base64" }),
        is_external: z.boolean(),
        is_multi_node_available: z.boolean(),
        multi_node_description: z.string().optional(),
        prices: ManagedServicesPricesBody,
        read_more_url: z.string().url(),
        service_name: ManagedServiceEnum,
        updated_at: updated_at.optional(),
        uuid,
        versions: z.array(z.string()),

        specification: z.any(), // TODO: kubernetes stuff
    })
    .openapi({ ref: "ServiceImage" });

export const ServiceImagesResponse = z.array(ServiceImage).openapi({ ref: "ServiceImagesResponse" });

export const ServiceSecrets = z
    .object({
        sql_pass: z.string(),
    })
    .openapi({ ref: "ServiceSecrets" });

export const ServiceWhiteList = z.array(ipv4).openapi({ ref: "ServiceWhiteList" });

export const ServiceUpdateBody = ManagedServiceBase.pick({
    display_name: true,
    billing_account_id: true,
})
    .partial()
    .openapi({ ref: "ServiceUpdateBody" });

export const ServiceWhitelistEntryBody = z
    .object({
        ip_address: ipv4,
    })
    .openapi({ ref: "ServiceWhitelistEntryBody" });

export const ServiceRebuildBody = z
    .object({
        replica_uuid: uuid,
        service_package_uuid: uuid,
    })
    .openapi({ ref: "ServiceRebuildBody" });

export const servicePaths: ZodOpenApiPathsObject = {
    "/{location}/user-resource/service/package/vm/rebuild": {
        post: {
            summary: "Rebuild a service",
            tags: ["services"],
            parameters: [params.location],
            requestBody: jsonBody(ServiceRebuildBody),
            responses: {
                ...successResponse(ManagedService),
            },
        },
    },
    "/user-resource/service/configurations": {
        get: {
            summary: "Get list of available Managed Services",
            tags: ["services"],
            responses: {
                ...successResponse(ServiceImagesResponse),
            },
        },
    },
    "/user-resource/service/package": {
        post: {
            summary: "Create new managed service",
            tags: ["services"],
            requestBody: jsonBody(ManagedServiceCreateBody),
            responses: {
                ...successResponse(ManagedService),
            },
        },
    },
    "/user-resource/service/package/{uuid}": {
        delete: {
            summary: "Delete Service Package",
            tags: ["services"],
            parameters: [params.uuid],
            responses: {
                ...successResponse(ManagedService),
            },
        },
        patch: {
            summary: "Update Service Package metadata",
            tags: ["services"],
            parameters: [params.uuid],
            requestBody: jsonBody(ServiceUpdateBody),
            responses: {
                ...successResponse(ManagedService),
            },
        },
    },
    "/user-resource/service/package/{uuid}/resources/{target_uuid}/start": {
        post: {
            summary: "Start a Managed Service",
            tags: ["services"],
            parameters: [params.uuid, params.target_uuid],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/user-resource/service/package/{uuid}/resources/{target_uuid}/stop": {
        post: {
            summary: "Stop a Managed Service",
            tags: ["services"],
            parameters: [params.uuid, params.target_uuid],
            responses: {
                ...simpleSuccessResponse,
            },
        },
    },
    "/user-resource/service/package/{uuid}/secrets": {
        get: {
            summary: "Get Managed Service secrets",
            tags: ["services"],
            parameters: [params.uuid],
            responses: {
                ...successResponse(ServiceSecrets),
            },
        },
    },
    "/user-resource/service/package/{uuid}/whitelist_addresses": {
        get: {
            summary: "Get Managed Service whitelist addresses",
            tags: ["services"],
            parameters: [params.uuid],
            responses: {
                ...successResponse(ServiceWhiteList),
            },
        },
        post: {
            summary: "Add whitelist address",
            tags: ["services"],
            parameters: [params.uuid],
            requestBody: jsonBody(ServiceWhitelistEntryBody),
            responses: {
                ...successResponse(ServiceWhiteList),
            },
        },
        delete: {
            summary: "Remove whitelist address",
            tags: ["services"],
            parameters: [params.uuid],
            requestBody: jsonBody(ServiceWhitelistEntryBody),
            responses: {
                ...successResponse(ServiceWhiteList),
            },
        },
    },
    "/user-resource/service/packages": {
        get: {
            summary: "Get list of services",
            tags: ["services"],
            responses: {
                ...successResponse(ServiceListResponse),
            },
        },
    },
};
