import { z } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, jsonBody, successResponse } from "../util.ts";
import { datetime_epoch_s } from "./common.ts";
import * as params from "./params.ts";

extendZodWithOpenApi(z);

const CampaignsType = z.enum(["all_users", "new_users"]).openapi({ ref: "CampaignsType" });

//#region Campaigns
export const Campaigns = z
    .object({
        campaign_code: z.string(),
        campaign_description: z.string(),
        campaign_type: CampaignsType,
        created_at: datetime_epoch_s,
        deleted_at: datetime_epoch_s.optional(),
        credit_amount: z.number(),
        id: z.number(),
        is_active: z.boolean(),
        is_deleted: z.boolean(),
        used_count: z.number(),
    })
    .openapi({ ref: "Campaigns" });

export const CampaignsCreateBody = z
    .object({
        campaign_code: z.string(),
        campaign_description: z.string(),
        campaign_type: CampaignsType,
        credit_amount: z.number(),
        is_active: z.boolean(),
    })
    .openapi({ ref: "CampaignsCreateBody" });

export const CampaignsUpdateBody = z
    .object({
        campaign_description: z.string(),
        is_active: z.boolean(),
    })
    .openapi({ ref: "CampaignsUpdateBody" });

export const CampaignsResponse = z.array(Campaigns);

//#endregion

export const campaignsPaths: ZodOpenApiPathsObject = {
    "/payment/admin/campaign/list": {
        get: {
            tags: ["campaigns"],
            summary: "List Campaigns",
            responses: {
                ...successResponse(CampaignsResponse),
                ...errorResponse(`Failed to list campaigns`),
            },
        },
    },
    "/payment/admin/campaign": {
        post: {
            tags: ["campaigns"],
            summary: "Create campaign",
            requestBody: jsonBody(CampaignsCreateBody),
            responses: {
                ...successResponse(CampaignsCreateBody),
                ...errorResponse(`Failed to create campaign`),
            },
        },
    },
    "/payment/admin/campaign/{id}": {
        put: {
            tags: ["campaigns"],
            summary: "Change campaign",
            requestBody: jsonBody(CampaignsUpdateBody),
            responses: {
                ...successResponse(CampaignsCreateBody),
                ...errorResponse(`Failed to change campaign`),
            },
        },
        delete: {
            summary: "Delete campaign",
            tags: ["campaigns"],
            parameters: [params.id],
            responses: {
                ...successResponse(Campaigns),
                ...errorResponse("Failed to delete campaign"),
            },
        },
    },
};
