import type { Auth0Error, WebAuth } from "auth0-js";
import { atom } from "jotai/vanilla";
import { getFullUrl } from "../../main/urls.ts";
import type { AuthProvider } from "../authProvider.ts";
import { auth0Realm } from "./auth0Config.ts";
import { webAuthAtom } from "./auth0Loader.ts";

export class Auth0Exception extends Error {
    name = "Auth0Exception";
    constructor(public readonly error: Auth0Error) {
        let message: string;
        if (typeof error.description === "object") {
            let parsedErrorData;
            try {
                const originalError = error.original! as { message: string };
                parsedErrorData = JSON.parse(originalError.message) as Record<string, string | object>;
            } catch {
                parsedErrorData = {};
            }
            message = String(parsedErrorData.message) ?? `Error code: ${error.code}`;
        } else {
            message = error.description ?? "Unknwon error";
        }

        super(message);
    }
}

export async function auth0Login(webAuth: WebAuth, username: string, password: string, state: string | undefined) {
    return await new Promise<void>((_resolve, reject) => {
        webAuth.login({ username, password, state, realm: auth0Realm }, (err) => {
            if (err) {
                console.error("Auth0 login error", err);
                reject(new Auth0Exception(err));
            } else {
                // This promise should never resolve, as the login should redirect
                reject(new Error("Login did not redirect"));
            }
        });
    });
}

export async function auth0Signup(webAuth: WebAuth, email: string, password: string) {
    return await new Promise<unknown>((resolve, reject) => {
        webAuth.signup({ email, password, connection: auth0Realm }, (err, result) => {
            if (err) {
                console.error("Auth0 signup error", err);
                reject(new Auth0Exception(err));
            } else {
                console.debug("Auth0 signup result", result);
                resolve(result);
            }
        });
    });
}

export async function auth0ForgotPassword(webAuth: WebAuth, email: string) {
    return await new Promise<unknown>((resolve, reject) => {
        webAuth.changePassword({ email, connection: auth0Realm }, (err, result) => {
            if (err) {
                console.error("Auth0 forgot password error", err);
                reject(new Auth0Exception(err));
            } else {
                console.debug("Auth0 forgot password result", result);
                resolve(result);
            }
        });
    });
}

export function auth0GoogleLogin(webAuth: WebAuth, state: string | undefined) {
    webAuth.authorize({ connection: "google-oauth2", state });
}

/**
 * NB: Auth0 logout is currently disabled, as it is not necessary, and requires manually configuring valid logout URLs
 * in the Auth0 settings.
 */
const realLogoutEnabled = false;

export function auth0Logout(webAuth: WebAuth, _redirectPath?: string) {
    if (realLogoutEnabled) {
        webAuth.logout({
            returnTo: getFullUrl("/"),
        });
    }
}

export async function auth0ChangePassword(webAuth: WebAuth, email: string) {
    return await new Promise<string>((resolve, _reject) => {
        webAuth.changePassword({ email, connection: auth0Realm }, (err, result: string) => {
            if (err) {
                throw new Auth0Exception(err);
            }
            resolve(result);
        });
    });
}

function encodeState(redirectPath: string | undefined) {
    return redirectPath !== undefined ? JSON.stringify(redirectPath) : undefined;
}

export const auth0ProviderAtom = atom((get): AuthProvider => {
    // NB: This is only a promise to load auth0, which will be awaited only when a method is called (for lazy loading)
    const webAuthP = get(webAuthAtom);
    return {
        signup: async ({ username, password }) => await auth0Signup(await webAuthP, username, password),
        login: async ({ username, password, redirectPath }) =>
            await auth0Login(await webAuthP, username, password, encodeState(redirectPath)),
        googleAuth: async ({ redirectPath }) => auth0GoogleLogin(await webAuthP, encodeState(redirectPath)),
        forgotPassword: async ({ email }) => await auth0ForgotPassword(await webAuthP, email),
        logout: async ({ redirectPath }) => auth0Logout(await webAuthP, redirectPath),
    };
});

export function exceptionToMessage(error: unknown): string {
    if (error instanceof Auth0Exception) {
        return error.message;
    }
    return String(error);
}
