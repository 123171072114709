import "./styles/index.css";

import { Provider, useAtomValue } from "jotai/react";
import { useHydrateAtoms } from "jotai/utils";
import { getDefaultStore } from "jotai/vanilla";
import React, { type ReactNode, StrictMode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { WButton } from "./components/button/WButton.tsx";
import { PageLoadingSuspense } from "./components/loading/Loading";
import { configFetchedAtom, configWaitFetchAtom } from "./config.fetch.ts";
import { mockConfigAtom } from "./config.mock.ts";
import { injectedConfigAtom } from "./config.ts";
import * as debugConfig from "./debugConfig";
import { showError } from "./dev/errorStream.ts";
import { ErrorToaster } from "./dev/ErrorToaster.tsx";
import { addDevVars } from "./dev/initDev";
import { isMockApiEnabled } from "./mock-api/msw/mswInit.ts";
import { apikeyAtom } from "./modules/api/apikey.store.ts";
import { PaymentReturn } from "./modules/billing/PaymentReturn.tsx";
import { getAuth0CallbackParams } from "./modules/login/auth0/auth0Callback.ts";
import { Auth0Callback } from "./modules/login/auth0/Auth0Login.tsx";
import { LoginsRoot } from "./modules/login/LoginsRoot.tsx";
import { ColorSchemeSwitcher } from "./modules/main/ColorSchemeSwitcher.tsx";
import { ErrorBox } from "./modules/main/ErrorBox.tsx";
import { errorInfoAsString, errorMessageAsString } from "./modules/main/errorToString.tsx";
import { basepath } from "./modules/main/urls.ts";
import { initTheme } from "./modules/theme/init.ts";
import { path_auth0Callback, path_paymentReturn } from "./paths.ts";
import { ScriptLoader } from "./ScriptLoader.tsx";
import { matchPath, removePrefix } from "./utils/paths.ts";

const MainRoot = React.lazy(() => import("./modules/main/MainRoot.tsx"));
// const LoginsRoot = React.lazy(() => import("./modules/login/LoginsRoot.tsx"));

addDevVars({ store: getDefaultStore() });

/** Simple routing for the root component based on the current path and whether the user is logged in. */
function useRootRoute(): ReactNode {
    const apikey = useAtomValue(apikeyAtom);

    const path = removePrefix(basepath, location.pathname);

    if (matchPath(path_auth0Callback, path) != null) {
        const params = getAuth0CallbackParams();
        if (params) {
            return <Auth0Callback params={params} />;
        } else {
            showError("No Auth0 callback params found");
        }
    } else if (matchPath(path_paymentReturn, path) != null) {
        return <PaymentReturn />;
    }

    if (apikey != null) {
        return <MainRoot />;
    } else {
        return <LoginsRoot />;
    }
}

function RootSwitch() {
    useInitializeRoot();

    const root = useRootRoute();

    return (
        <>
            <ScriptLoader />
            {root}
        </>
    );
}

/** Must run before any components are rendered */
function useInitializeRoot() {
    const isMockApi = isMockApiEnabled();

    // NB: Makes sure config has been fetched before loading any components
    useAtomValue(isMockApi ? mockConfigAtom : configWaitFetchAtom);

    useHydrateAtoms([[injectedConfigAtom, isMockApi ? mockConfigAtom : configFetchedAtom]]);

    // NB: Initialize theme before rendering any components but after loading config (since it uses `siteTheme`)
    initTheme();
}

export function Root() {
    // TODO: <LoadingSuspense> will be rendered without theme styles when config loading takes too long
    let rootNode = (
        <MainErrorBoundary>
            <Provider store={getDefaultStore()}>
                {import.meta.env.DEV && <ErrorToaster />}
                <ColorSchemeSwitcher />
                <PageLoadingSuspense>
                    <RootSwitch />
                </PageLoadingSuspense>
            </Provider>
        </MainErrorBoundary>
    );
    if (import.meta.env.DEV && debugConfig.reactStrictMode) {
        rootNode = <StrictMode>{rootNode}</StrictMode>;
    }
    return rootNode;
}

export function MainErrorMessage({ error }: { error: unknown }) {
    const message = errorMessageAsString(error);
    const info = errorInfoAsString(error);
    return (
        <ErrorBox>
            <p className="text-left mb-2">{message}</p>
            {info && <div className="text-muted mb-2">({info})</div>}
            <WButton className="mt-2" action={() => location.reload()} color="primary">
                Reload page
            </WButton>
        </ErrorBox>
    );
}

function MainErrorBoundary({ children }: { children: ReactNode }) {
    return <ErrorBoundary FallbackComponent={MainErrorMessage}>{children}</ErrorBoundary>;
}

export default Root;
