import { z } from "zod";
import type { ZodOpenApiPathsObject } from "zod-openapi";
import { extendZodWithOpenApi } from "zod-openapi";
import { errorResponse, jsonBody, successResponse } from "../util.ts";
import { AccessImpersonation } from "./access.js";
import { datetime, datetime_usec, user_id } from "./common.js";
import * as params from "./params.js";

extendZodWithOpenApi(z);

//#region User profile

const UserProfileBaseData = z
    .object({
        user_id: user_id,
        last_name: z.string().nullable().optional(),
        lang: z.string().nullable(),
        avatar: z.string().nullable(),
        id: user_id,
        first_name: z.string().nullable().optional(),
        email: z.string().nullable(),
        // XXX: These are not always optional, but eg. the PATCH request response does not include them
        personal_id_number: z.string().nullable().optional(),
        phone_number: z.string().nullable().optional(),
    })
    .openapi({ ref: "UserProfileBaseData" });

const UserProfileData = UserProfileBaseData.extend({
    updated_at: datetime_usec.nullable(),
    created_at: datetime_usec,
}).openapi({ ref: "UserProfileData" });

const UserProfilePatchRequest = UserProfileBaseData.partial().openapi({ ref: "UserProfilePatchRequest" });

// Ugh, the response format is inconsistent between endpoints...
const UserProfilePatchResponse = UserProfileBaseData.extend({ updated_at: datetime, created_at: datetime }).openapi({
    ref: "UserProfilePatchResponse",
});

export const User = z
    .object({
        id: user_id,
        cookie_id: z.string(),
        name: z.string(),
        signup_site: z.string().optional(),
        last_activity: datetime.optional(),
        profile_data: UserProfileData,
        // XXX: Only in Auth0CallbackResponse
        kong_key: z.string().optional(),
    })
    .openapi({ ref: "User" });

//#endregion

//#region User MFA
const UserMfaResponse = z
    .object({
        success: z.boolean(),
    })
    .openapi({ ref: "UserMfaResponse" });

const UserMfaActivateResponse = z
    .object({
        ticket_id: z.string(),
        ticket_url: z.string(),
    })
    .openapi({ ref: "UserMfaActivateResponse" });
//#endregion

const ImpersonationStartResponse = z
    .object({
        token: z.string(),
    })
    .openapi({ ref: "ImpersonationStartResponse" });

//#region Auth0
const Auth0CallbackBody = z
    .object({
        access_token: z.string(),
        id_token: z.string(),
        scope: z.string(),
        expires_in: z.string(),
        token_type: z.string(),
        state: z.string(),
    })
    .openapi({ ref: "Auth0CallbackBody" });

const Auth0CallbackResponse = z
    .object({
        apikey: z.string(),
        user: User,
    })
    .openapi({ ref: "Auth0CallbackResponse" });
//#endregion

export const userPaths: ZodOpenApiPathsObject = {
    "/user-resource/user": {
        get: {
            summary: "Get current user information",
            tags: ["user"],
            responses: {
                ...successResponse(User),
                ...errorResponse("Failed to get user"),
            },
        },
    },
    "/user-resource/user/profile": {
        patch: {
            summary: "Update current user details",
            tags: ["user"],
            requestBody: jsonBody(UserProfilePatchRequest),
            responses: {
                ...successResponse(UserProfilePatchResponse, "Details updated"),
                // TODO: This is wrong, the error response is a HTTP 400
                ...errorResponse("Failed to update details"),
            },
        },
    },
    "/user-resource/mfa/is_active": {
        get: {
            summary: "Get MFA status",
            tags: ["user"],
            responses: {
                ...successResponse(UserMfaResponse),
                ...errorResponse("Failed to get mfa"),
            },
        },
    },
    "/user-resource/mfa/activate": {
        post: {
            summary: "Activate MFA",
            tags: ["user"],
            responses: {
                ...successResponse(UserMfaActivateResponse),
                ...errorResponse("Failed to activate mfa"),
            },
        },
    },
    "/user-resource/mfa/deactivate": {
        post: {
            summary: "De-activate MFA",
            tags: ["user"],
            responses: {
                ...successResponse(UserMfaResponse),
                ...errorResponse("Failed to deactivate mfa"),
            },
        },
    },
    "/user-resource/impersonation/{uuid}/accept": {
        put: {
            summary: "Accept invitation",
            tags: ["user"],
            parameters: [params.uuid],
            responses: {
                ...successResponse(AccessImpersonation, "Access invitation accepted"),
                ...errorResponse("Failed to accept invitation"),
            },
        },
    },
    "/user-resource/impersonation/{uuid}/start": {
        post: {
            summary: "Start impersonation",
            tags: ["user"],
            parameters: [params.uuid],
            responses: {
                ...successResponse(ImpersonationStartResponse),
            },
        },
    },
    "/user-resource/auth0_callback": {
        post: {
            summary: "Create API key from Auth0 callback data",
            tags: ["user"],
            requestBody: jsonBody(Auth0CallbackBody),
            responses: {
                ...successResponse(Auth0CallbackResponse),
            },
        },
    },
    "/user-resource/user/privileges": {
        get: {
            summary: "Get current user privileges",
            tags: ["user"],
            responses: {
                "403": {
                    description: "User is not an administrator",
                    content: { "application/json": { schema: z.object({ message: z.string() }) } },
                },
                "404": {
                    description: "User is an administrator",
                    content: { "application/json": { schema: z.object({ message: z.string() }) } },
                },
                "200": {
                    description: "User is an administrator (currently not used)",
                },
                "502": {
                    description: "User is an administrator (Bad Gateway)",
                },
            },
        },
    },
};
